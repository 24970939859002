export const invoiceStatusConstant = [
  {
    key: "UNPAID",
    label: "UNPAID",
    tooltip: "",
  },
  {
    key: "PAID",
    label: "PAID",
    tooltip: "",
  },
  {
    key: "PARTIAL",
    label: "PARTIAL",
    tooltip: "",
  },
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "PRINTED",
    label: "PRINTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const purchaseSaleOrderStatusConstant = [
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "NEW",
    label: "NEW",
    tooltip: "",
  },
  {
    key: "ACCEPTED",
    label: "ACCEPTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const quotationStatusConstant = [
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "NEW",
    label: "NEW",
    tooltip: "",
  },
  {
    key: "ACCEPTED",
    label: "ACCEPTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const orderStatusConstant = [
  {
    key: "NEW",
    label: "NEW",
    tooltip: "Order Created",
  },
  {
    key: "UPDATED",
    label: "UPDATED",
    tooltip: "Order updated before payment made",
  },
  {
    key: "PAYMENT_CONFIRMED",
    label: "PAYMENT CONFIRMED",
    tooltip: "Invoice paid",
  },
  {
    key: "PAYMENT_UPDATED",
    label: "PAYMENT UPDATED",
    tooltip: "Invoice paid",
  },
  {
    key: "ORDER_DELIVERED",
    label: "ORDER DELIVERED",
    tooltip: "Items delivered",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const recurringStatusConstant = [
  {
    key: "SCHEDULED",
    label: "SCHEDULED",
    value: "SCHEDULED", // use by react-x-editable
    text: "SCHEDULED", // use by react-x-editable
    tooltip: "",
  },
  {
    key: "FINISHED",
    label: "FINISHED",
    value: "FINISHED", // use by react-x-editable
    text: "FINISHED", // use by react-x-editable
    tooltip: "",
  },
  {
    key: "FAILED",
    label: "FAILED",
    value: "FAILED", // use by react-x-editable
    text: "FAILED", // use by react-x-editable
    tooltip: "",
  },
  {
    key: "RESUMED",
    label: "RESUMED",
    value: "RESUMED", // use by react-x-editable
    text: "RESUMED", // use by react-x-editable
    tooltip: "",
  },
];
