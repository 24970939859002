import { CCol, CInput, CRow } from "@coreui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiSolidRightArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { onFilterChangeHandler } from "src/utils/tableUtils";
export const DateRangePicker = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  var start = useSelector((state) => state.invoiceDateFilterStart);
  var end = useSelector((state) => state.invoiceDateFilterEnd);

  return (
    <>
      <CRow>
        <CCol className="mr-0 pr-0">
          <CInput
            type="date"
            className="col-input"
            onChange={(e) => {
              if (props.setStartDateFilter) {
                props.setStartDateFilter(handleDateConversion(e.target.value));
              } else {
                onFilterChangeHandler(
                  props?.search,
                  props?.setSearch,
                  props?.statusType,
                  handleDateConversion(e.target.value),
                  ">="
                );
              }
              dispatch({
                type: "set",
                invoiceDateFilterStart: new Date(
                  handleDateConversion(e.target.value)
                ),
              });
            }}
            defaultValue={moment(start).format("YYYY-MM-DD")}
          />
        </CCol>
        <CCol
          sm="1 px-0 mx-0 d-flex justify-content-center"
          style={{ maxWidth: "unset", flex: "0 0 0" }}
        >
          <BiSolidRightArrowAlt style={{ height: "100%" }} />
        </CCol>
        <CCol className="ml-0 pl-0">
          <CInput
            type="date"
            className="col-input"
            onChange={(e) => {
              if (props.setEndDateFilter) {
                props.setEndDateFilter(
                  handleDateConversion(e.target.value, true)
                );
              } else {
                onFilterChangeHandler(
                  props?.search,
                  props?.setSearch,
                  props?.statusType,
                  handleDateConversion(e.target.value, true),
                  "<="
                );
              }
              dispatch({
                type: "set",
                invoiceDateFilterEnd: new Date(
                  handleDateConversion(e.target.value)
                ),
              });
            }}
            defaultValue={moment(end).format("YYYY-MM-DD")}
          />
        </CCol>
      </CRow>
    </>
  );
};

export const handleDateConversion = (val, end) => {
  if (end) {
    return moment(new Date(val)).endOf("day").valueOf();
  }
  return moment(new Date(val)).startOf("day").valueOf();
};
