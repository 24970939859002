import {
  CButton,
  CCol,
  CContainer,
  CInputGroup,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { canUseDOM } from "@coreui/react/src/utils/helper";
import moment from "moment";
import {
  default as React,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BiDownload, BiPrinter } from "react-icons/bi";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { get, getBaseUrl, post } from "src/services/dataService";
import {
  SHORT_LINK_FETCH_API,
  TRANSACTION_FETCH_PUBLIC_API,
} from "src/services/pathRequest";
import {
  safeQueryBuilder,
  setHtmlDescription,
  setHtmlTitle,
  twoDecimal,
} from "src/utils/formUtils";
import { formatDouble, isMobileDevice } from "src/utils/tableUtils";
import Swal from "sweetalert2";

const InvoicePublic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [trxId, setTrxId] = useState();
  const [invoiceType, setInvoiceType] = useState();
  const [isInvoice, setIsInvoice] = useState(false);
  const [getLongLink] = useMutation(
    (form) =>
      post({
        path: SHORT_LINK_FETCH_API,
        formData: form,
      }),
    {
      onSettled: Swal.close(),
    }
  );

  const componentToPrintRef = useRef(null);
  const [products, setProducts] = useState(null);

  const handlePrint = (e) => {
    e.preventDefault();
    canUseDOM && print();
  };

  const print = useReactToPrint({
    content: () => componentToPrintRef.current,
    documentTitle: history.location?.state?.data?.billId
      ? `bill_#${history.location?.state?.data?.billId}`
      : Date.now(),
  });

  const [getTrxInfo, { data: transactionInfo }] = useMutation(
    (transactionId) =>
      get({
        path: safeQueryBuilder(TRANSACTION_FETCH_PUBLIC_API, {
          id: transactionId,
        }),
      }),
    {
      onSettled: Swal.close(),
      onSuccess: (response) => {
        setIsInvoice(response?.data?.billType == "INVOICE");
      },
    }
  );

  useEffect(() => {
    getLongLink({ shortLink: document.URL }).then((response) => {
      var longLink = response?.data?.result;

      const url = new URL(longLink);
      const searchParams = url.searchParams;

      var queryType = searchParams.get("t");
      var transactionId = searchParams.get("i");
      setTrxId(transactionId);
      if (queryType == "bill") {
        getTrxInfo(transactionId).then((response) => {
          setProducts(response?.data?.products);
        });
      }
    });
  }, []);

  var to = transactionInfo?.data?.partner;
  var billType = transactionInfo?.data?.billType;
  var billId = transactionInfo?.data?.billId;
  var billStatus = transactionInfo?.data?.billStatus;
  var defaultTnC = transactionInfo?.data?.defaultTnC;
  var defaultFooter = transactionInfo?.data?.defaultFooter;
  var brandColor = transactionInfo?.data?.brandColor;
  var paymentTransactions = transactionInfo?.data?.paymentTransactions ?? [];
  var createdDate = transactionInfo?.data?.createdDate;
  var transactionDate = transactionInfo?.data?.transactionDate;
  var dueDate = transactionInfo?.data?.dueDate;
  var telNo = transactionInfo?.data?.telNo;
  var notes = transactionInfo?.data?.description;
  var total = transactionInfo?.data?.value;
  var balance = transactionInfo?.data?.partialBalance;
  var billReferenceId = transactionInfo?.data?.billReferenceId;
  var isDebit = transactionInfo?.data?.isDebit;

  var company = transactionInfo?.data?.company;
  var address = company?.companyAddress?.split(",");
  var delivery = transactionInfo?.data?.delivery;

  var discount = products
    ?.map((m) => {
      if (["delivery"]?.includes(m?.name?.toLowerCase())) return;

      var subTotal =
        m?.stockToCount * isDebit ? m?.priceSellPerUnit : m?.priceBuyPerUnit;

      if (m?.discountType == "percent" && m?.discount) {
        return (Number(subTotal) * Number(m?.discount ?? 0)) / 100;
      } else if (m?.discountType == "flat" && m?.discount) {
        return Number(m?.discount ?? 0);
      }
      return 0;
    })
    .reduce((p, c) => (p ?? 0) + (c ?? 0), 0);

  useLayoutEffect(() => {
    setHtmlTitle(company?.companyName);
    setHtmlDescription("description", `E-invoice #${billId}`);
  });

  return transactionInfo?.data?.billId ? (
    <CContainer
      style={{
        maxWidth: "900.70px",
        marginTop: "30px",
      }}
      className="invoiceV2"
    >
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={"E-invoice #" + billId} />
        <meta
          property="og:image"
          itemprop="image primaryImageOfPage"
          content={getBaseUrl("eas") + `/image/logo/fetch?id=${trxId}`}
        />
        <meta
          name="twitter:title"
          property="og:title"
          itemprop="name"
          content={company.companyName}
        />
        <meta
          name="twitter:description"
          property="og:description"
          itemprop="description"
          content={"E-invoice #" + billId}
        />
      </Helmet>
      {["INVOICE"]?.includes(billType) && (
        <CRow className="d-print-none">
          <CCol sm="6">
            <CInputGroup className="mb-3">
              <CSelect
                custom
                name="type"
                id="type"
                onChange={async (e) => {
                  const { value } = e.target;
                  setInvoiceType(value);
                }}
              >
                <option key="invoice" value="invoice" title="invoice">
                  {"INVOICE"}
                </option>
                <option key="receipt" value="receipt" title="receipt">
                  {"RECEIPT"}
                </option>
              </CSelect>
            </CInputGroup>
          </CCol>
        </CRow>
      )}
      <div
        className="invoice-box mb-5"
        ref={componentToPrintRef}
        style={{
          backgroundColor: "white",
          WebkitPrintColorAdjust: "exact",
        }}
      >
        <CRow>
          <CCol className="float-right">
            <CButton
              className="mr-1 float-right d-print-none"
              size="sm"
              color="secondary"
              onClick={handlePrint}
            >
              {isMobileDevice(window) ? <BiDownload /> : <BiPrinter />}{" "}
              {isMobileDevice(window) ? "Download" : "Print"}
            </CButton>
          </CCol>
        </CRow>
        <CRow className="mx-3 my-3">
          <CCol>
            <CRow
              className="mb-3"
              style={{
                borderBottom: "1px solid",
                borderBottomColor: "#DCDCDC",
                paddingBottom: "1rem",
              }}
            >
              <CCol sm="8">
                <CRow>
                  <CCol sm="4" className="text-center">
                    <img
                      style={{ maxHeight: "100px", maxWidth: "150px" }}
                      src={getBaseUrl("eas") + `/image/logo/fetch?id=${trxId}`}
                      alt={company?.companyName}
                    />
                  </CCol>
                  <CCol>
                    {company?.companyName ? (
                      <h4 style={{ color: brandColor }}>
                        {company?.companyName}
                      </h4>
                    ) : null}

                    {address ? (
                      <p
                        style={{ whiteSpace: "pre-line" }}
                        className="mb-1 font-weight-bold"
                      >
                        {address}
                      </p>
                    ) : null}

                    {telNo ? <p className="font-weight-bold">{telNo}</p> : null}
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="4">
                <CRow>
                  <CCol>
                    <h5 className="float-right" style={{ color: brandColor }}>
                      {isInvoice &&
                      transactionInfo?.data?.configType == "PURCHASE"
                        ? "PURCHASE INVOICE"
                        : isInvoice &&
                          transactionInfo?.data?.billType != "QUOTATION" &&
                          (transactionInfo?.data?.configType == "SALES" ||
                            transactionInfo?.data?.configType ==
                              "INCOME OTHERS")
                        ? invoiceType == "receipt"
                          ? "RECEIPT"
                          : "INVOICE"
                        : isInvoice &&
                          transactionInfo?.data?.billType != "QUOTATION" &&
                          transactionInfo?.data?.configType == "EXPENSES"
                        ? "EXPENSE VOUCHER"
                        : transactionInfo?.data?.billType?.includes("ORDER") &&
                          transactionInfo?.data?.configType == "PURCHASE"
                        ? "PURCHASE ORDER"
                        : transactionInfo?.data?.billType?.includes("ORDER")
                        ? "SALE ORDER"
                        : "QUOTATION"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <p className="float-right font-weight-bold">#{billId}</p>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CRow>
                  <CCol sm="8">
                    <h4 style={{ color: brandColor }}>
                      {to?.companyName ? to?.companyName : to?.name}
                    </h4>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CRow>
                      <CCol sm="8">
                        <CRow className="mb-1">
                          <CCol sm="3 text-muted">{t("Address")}</CCol>
                          <CCol
                            className="font-weight-bold"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {to?.address ?? "-"}
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol sm="4">
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">{t("Issue Date")}</CCol>
                          <CCol className="font-weight-bold">
                            <label className="float-right">
                              {transactionDate
                                ? moment(transactionDate).format("DD/MM/YYYY")
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="8">
                        <CRow className="mb-1">
                          <CCol sm="3 text-muted">{t("Email")}</CCol>
                          <CCol className="font-weight-bold">
                            {to?.email ?? "-"}
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol sm="4">
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">{t("Due Date")}</CCol>
                          <CCol className="font-weight-bold ">
                            <label className="float-right">
                              {dueDate
                                ? moment(dueDate).format("DD/MM/YYYY")
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="8">
                        <CRow className="mb-1">
                          <CCol sm="3 text-muted">{t("Phone")}</CCol>
                          <CCol className="font-weight-bold">
                            {to?.telNo ?? "-"}
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol sm="4">
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">{t("Amount")}</CCol>
                          <CCol className="font-weight-bold">
                            <label className="float-right">
                              {total
                                ? "RM" + formatDouble(twoDecimal(total))
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="8">
                        <CRow className="mb-1">
                          <CCol sm="3 text-muted">{t("Payment Status")}</CCol>
                          <CCol className="font-weight-bold">
                            <label>{billStatus}</label>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol sm="4">
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">{t("Paid Amount")}</CCol>
                          <CCol className="font-weight-bold">
                            <label className="float-right">
                              {total
                                ? "RM" +
                                  formatDouble(twoDecimal(total - balance))
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-5 mb-3">
              <CCol>
                <CRow
                  className="mb-2"
                  style={{
                    background: brandColor,
                    color: "white",
                  }}
                >
                  <CCol sm="6" className="mt-1">
                    <h5 className="align-middle">{t("Items")}</h5>
                  </CCol>
                  <CCol sm="2" className="mt-1">
                    <h5 className="float-right align-middle">
                      {t("Price/unit")}
                    </h5>
                  </CCol>
                  <CCol sm="2" className="mt-1">
                    <h5 className="float-right align-middle">
                      {t("Discount")}
                    </h5>
                  </CCol>
                  <CCol className="mt-1">
                    <h5 className="float-right align-middle">
                      {t("Sub Price")}
                    </h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    {products?.map((product, index) => {
                      if (
                        ["discount", "delivery"]?.includes(
                          product?.name?.toLowerCase()
                        )
                      )
                        return;

                      return (
                        <CRow
                          className="page-break"
                          style={{
                            marginBottom: "2px",
                            borderBottom: "1px solid",
                            borderBottomColor: "#DCDCDC",
                          }}
                          key={product.name}
                        >
                          <CCol sm="6">
                            <CRow>
                              <CCol className="font-weight-bold">
                                <label>
                                  {index + 1}. {product.name}
                                </label>{" "}
                                <label className="text-muted">
                                  x {product?.stockToCount}
                                </label>
                              </CCol>
                            </CRow>
                            <CRow className="page-break">
                              <CCol
                                style={{ whiteSpace: "pre-line" }}
                                className="ml-4 text-muted "
                              >
                                <label style={{ fontSize: "12px" }}>
                                  {product.description ?? "-"}
                                </label>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol sm="2">
                            <label className="float-right">
                              RM
                              {formatDouble(
                                twoDecimal(
                                  isDebit
                                    ? product?.priceSellPerUnit
                                    : product?.priceBuyPerUnit
                                )
                              )}
                            </label>
                          </CCol>
                          <CCol sm="2">
                            <label className="float-right">
                              {product?.discountType == "percent"
                                ? twoDecimal(product?.discount) + "%"
                                : "RM" + twoDecimal(product?.discount)}
                            </label>
                          </CCol>
                          <CCol>
                            <label className="float-right">
                              RM
                              {product?.discountType == "percent"
                                ? formatDouble(
                                    twoDecimal(
                                      product?.stockToCount *
                                        (isDebit
                                          ? (product?.priceSellPerUnit *
                                              (100 - product?.discount ?? 0)) /
                                            100
                                          : (product?.priceBuyPerUnit *
                                              (100 - product?.discount ?? 0)) /
                                            100)
                                    )
                                  )
                                : formatDouble(
                                    twoDecimal(
                                      product?.stockToCount *
                                        (isDebit
                                          ? product?.priceSellPerUnit
                                          : product?.priceBuyPerUnit) -
                                        (product?.discount ?? 0)
                                    )
                                  )}
                            </label>
                          </CCol>
                        </CRow>
                      );
                    })}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-4 page-break">
              <CCol sm="8">{/* leave blank */}</CCol>
              <CCol sm="4">
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("SubTotal")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total
                        ? "RM" + formatDouble(twoDecimal(total - delivery))
                        : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow className="page-break">
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Discount")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + formatDouble(twoDecimal(discount)) : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow className="page-break">
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Delivery")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + formatDouble(twoDecimal(delivery)) : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow
                  className="page-break"
                  style={{
                    background: brandColor,
                    color: "white",
                  }}
                >
                  <CCol>
                    <h5 className="float-right pt-2">
                      {isInvoice ? t("Total Due:") : t("Total")}
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right pt-2">
                      {total ? "RM" + formatDouble(twoDecimal(balance)) : "-"}
                    </h5>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-4 page-break">
              <CCol>
                <CRow>
                  <CCol>
                    <h5 style={{ color: brandColor }}>{t("Terms / Notes")}</h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol style={{ whiteSpace: "pre-line" }}>
                    {notes || defaultTnC
                      ? (defaultTnC ?? "") + "\n" + (notes ?? "")
                      : "-"}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>

            {["INVOICE"]?.includes(billType) && (
              <CRow className="mt-4">
                <CCol>
                  <CRow>
                    <CCol>
                      <h5 style={{ color: brandColor }}>
                        {t("Payment Details")}
                      </h5>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CRow
                        className="mb-2"
                        style={{
                          background: brandColor,
                          color: "white",
                          WebkitPrintColorAdjust: "exact",
                        }}
                      >
                        <CCol sm="3" className="mt-1">
                          <h5 className="align-middle">{t("Date")}</h5>
                        </CCol>
                        <CCol className="mt-1">
                          <h5 className="text-center align-middle">
                            {t("Total")}
                          </h5>
                        </CCol>
                        <CCol className="mt-1">
                          <h5 className="text-center align-middle">
                            {t("Method")}
                          </h5>
                        </CCol>
                        <CCol className="mt-1">
                          <h5 className="text-center align-middle">
                            {t("Notes")}
                          </h5>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol>
                          {paymentTransactions?.map((payment, index) => {
                            if (!["VALUE"]?.includes(payment?.type)) return;

                            return (
                              <CRow
                                className="page-break"
                                style={{
                                  marginBottom: "2px",
                                  borderBottom: "1px solid",
                                  borderBottomColor: "#DCDCDC",
                                }}
                                key={payment.paymentDate}
                              >
                                <CCol sm="3">
                                  <CRow>
                                    <CCol className="font-weight-bold">
                                      <label>
                                        {index + 1}.{" "}
                                        {moment(payment?.paymentDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </label>
                                    </CCol>
                                  </CRow>
                                </CCol>
                                <CCol className="text-center align-middle">
                                  <label>
                                    RM{formatDouble(payment?.amount)}
                                  </label>
                                </CCol>
                                <CCol className="text-center align-middle">
                                  <label>
                                    {payment?.transactionMethod?.replace(
                                      "_",
                                      " "
                                    )}
                                  </label>
                                </CCol>
                                <CCol className="text-center align-middle">
                                  <label>{payment.description ?? "-"}</label>
                                </CCol>
                              </CRow>
                            );
                          })}
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            )}

            <CRow className="mt-3">
              <CCol className="text-center align-middle">
                <CLabel>{defaultFooter}</CLabel>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </CContainer>
  ) : (
    <CContainer>
      <CRow className="justify-content-center flex-col">
        <div className="d-flex justify-content-center">
          <p>
            Your invoice might be deleted or updated. Please request new invoice
            link from your seller
          </p>
        </div>
      </CRow>
    </CContainer>
  );
};

export default InvoicePublic;
