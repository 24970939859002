import moment from "moment";
import "moment/locale/en-au";
import "moment/locale/ms";
import { CompanyInfo } from "src/services/queryService";

export const MomentLocale = () => {
  CompanyInfo((response) => {
    const { locale } = response?.data;
    moment.locale("en".includes(locale) ? "en-sg" : locale);
  });

  return moment;
};

export const getStartDate = (start, end) => {
  const s = moment(start);
  const e = moment(end);
  if (moment.duration(e.diff(s)).asDays() < 93) return s;

  return e.subtract(1, "months").startOf("month");
};
