import {
  CButton,
  CCol,
  CDropdownItem,
  CInput,
  CLabel,
  CLink,
  CPagination,
  CSelect,
  CTooltip,
} from "@coreui/react";
import { CRow } from "@coreui/react-pro";
import capitalize from "capitalize";
import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsFiletypePdf, BsPlusCircleFill } from "react-icons/bs";
import { FaFileCsv } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  invoiceStatusConstant,
  purchaseSaleOrderStatusConstant,
  quotationStatusConstant,
  recurringStatusConstant,
} from "src/dataType/bill";
import { orderType } from "src/dataType/orderType";
import { projectConfigType } from "src/dataType/projectConfigType";
import { get, getBaseUrl } from "src/services/dataService";
import { TRANSACTION_SUMMARY_FETCHALL_API } from "src/services/pathRequest";
import {
  CategoryListMutate,
  GetPaymentSummary,
  InventoryListMutate,
  ProductBrandCategory,
  ProductCategoryMutate,
  ProjectListMutate,
} from "src/services/queryService";
import { v4 as uuid } from "uuid";
import { DateRangePicker as DateRangePickerStock } from "../views/base/daterangepicker/DateRangePicker";
import {
  ColoredLine,
  searchQueryBuilder,
  updateTransactionTotalValue,
} from "./formUtils";
const commaNumber = require("comma-number");

export const getSortBy = (ele) => {
  switch (ele) {
    case "project":
      return "projectTitle";
    case "last update":
      return "modifiedDate";
    case "start date":
      return "startDate";
    case "transaction type":
      return "isDebit";
    default:
      return ele;
  }
};

export const getBadge = (status) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "danger";
    case "ACTIVE":
      return "success";
    case "PENDING_PAYMENT":
      return "danger";
    case "PENDING_REGISTRATION":
      return "secondary";
    case "TRIAL":
      return "warning";
    case "PROGRESS":
      return "success";
    case "ORDER_DELIVERED":
      return "success";
    case "PAYMENT_CONFIRMED":
      return "success";
    case "CLOSED":
      return "secondary";
    case "PENDING":
      return "warning";
    case "PENDING_REVIEW":
      return "warning";
    case "REJECTED":
      return "danger";
    case "SUCCESS":
      return "success";
    case "FINISHED":
      return "success";
    case "FAILED":
      return "warning";
    case "PENDING_RETRY":
      return "warning";
    case "FAILED_PROCESS":
      return "danger";
    case "PENDING_PROCESS":
      return "secondary";
    case "PAID":
      return "success";
    case "UNPAID":
      return "warning";
    case "CANCELED":
      return "secondary";
    default:
      return "primary";
  }
};

export const getTransactionType = (transactionType) => {
  if (transactionType) return "DEBIT";
  return "CREDIT";
};

export const loader = () => <Loader />;

export const Loader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border align-self-center" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const tableBasicParams = (page, sortBy, isAsc) => {
  return { pn: page < 1 ? page : page - 1, sb: sortBy, isAsc: isAsc };
};
export const tableBasicParamsWithRow = (page, sortBy, isAsc, row = 10) => {
  return { pn: page < 1 ? page : page - 1, sb: sortBy, isAsc: isAsc, rn: row };
};

export const formatDouble = (val) => {
  if (!val) return Number("0.0").toFixed(2);
  const num = val.toString();
  // const dec = num.split(".")[1];
  // const len = dec && dec.length > 2 ? dec.length : 2;
  return commaNumber(Number(num).toFixed(2));
};

export const formatDoubleWithoutCommaAndReturnNumberType = (val) => {
  if (!val) return Number("0.0").toFixed(2);
  const num = val.toString();
  return parseFloat(Number(num).toFixed(2));
};

export const isMobileTablet = (window) => {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ios|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera
  );
  return check;
};

export const isMobileApp = (window) => {
  return ["android_app", "ios_app"].includes(window?.navigator?.userAgent);
};

export const getWindowSize = (window) => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const isMobileDevice = (window) => {
  return isMobileTablet(window);
  // const { innerWidth } = getWindowSize(window);
  // if (innerWidth < 1300) return true;
  // return false;
};

export const isMobileDeviceForItemForm = (window) => {
  return isMobileTablet(window);
  // const { innerWidth } = getWindowSize(window);
  // if (innerWidth < 1600) return true;
  // return false;
};

export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
};

export const exportTableToPdf = (func, disabled, title) => (
  <div
    className="float-right"
    style={{
      marginRight: "2px",
      cursor: disabled ? "not-allowed" : "pointer",
    }}
  >
    <CButton
      title="Select only one branch in global filter"
      onClick={func}
      style={{
        backgroundColor: "#4b77be",
        color: "white",
        border: "1px solid",
        borderColor: "#4b77be",
      }}
      disabled={disabled}
    >
      <BsFiletypePdf size={15} style={{ verticalAlign: "inherit" }} />{" "}
      <span>{title ?? "Export"}</span>
    </CButton>
  </div>
);

export const exportTableToCsv = (fileName, fnContent, disabled, style) => (
  <div
    className="float-right"
    style={{
      marginRight: "2px",
      cursor: disabled ? "not-allowed" : "pointer",
      height: "100%",
    }}
  >
    <CButton
      style={style || { backgroundColor: "#4b77be", color: "white" }}
      className="float-right"
      href={fnContent()}
      download={fileName + ".csv"}
      target="_blank"
    >
      <FaFileCsv size={15} style={{ verticalAlign: "inherit" }} />{" "}
      <span>Csv</span>
    </CButton>
  </div>
);

export const csvContent = (content, title) => {
  return (
    "data:text/csv;charset=utf-8," + encodeURIComponent(title + "\n" + content)
  );
};

export const addNew = (title, callback, color) => {
  return addHeaderButton(
    title,
    callback,
    <BsPlusCircleFill color={color ?? "blue"} size={15} />
  );
};

export const addHeaderButton = (title, callback, logo, color, margin) => {
  return (
    <div
      style={{
        float: "right",
        marginRight: margin ?? "2px",
        cursor: "pointer",
        color: color ?? "blue",
      }}
      onClick={callback}
      onKeyDown={callback}
    >
      <CButton
        variant="outline"
        style={{ borderColor: "#4b77be", color: "#4b77be" }}
      >
        {logo} <span>{title}</span>
      </CButton>
    </div>
  );
};

export const Pagination = (activePage, pageDate, callback) => {
  const { t } = useTranslation();

  return (
    <div>
      <CRow>
        <CCol xs="12" sm="6">
          <div className="float-left">
            {t("Showing ")} {pageDate?.numberOfElement ?? 0} {t(" out of ")}{" "}
            {pageDate?.totalElement ?? 0}
          </div>
        </CCol>
        <CCol>
          {pageDate?.totalNumber ? (
            <CPagination
              pages={pageDate?.totalNumber}
              onActivePageChange={(i) => callback(i)}
              activePage={Number(activePage)}
              dots
              align="end"
            />
          ) : (
            ""
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export const actionButton = (title, callback, logo, css) => {
  return (
    <div style={{ marginBottom: "8px" }}>
      <CLink
        className="add-new-summary hover-underline-animation"
        onClick={callback}
        style={css}
      >
        {title ? capitalize(title) : logo}
      </CLink>
      <br />
    </div>
  );
};

export const actionButtonAsMenu = (title, callback, logo, css) => {
  return (
    <CDropdownItem
      className="add-new-summary hover-underline-animation-menu"
      onClick={callback}
      style={css}
    >
      {title ? capitalize(title) : logo}
    </CDropdownItem>
  );
};

export const submitButton = (
  title,
  callback,
  loading,
  color,
  styles,
  logo,
  width,
  disabled
) => {
  return pillButton(
    title,
    callback,
    loading,
    color,
    styles,
    logo,
    width,
    disabled
  );
};

export const pillButton = (
  title,
  callback,
  loading,
  color,
  styles,
  logo,
  width = "w-25",
  disabled = false
) => {
  return (
    <CButton
      color={color ?? "primary"}
      onClick={callback}
      disabled={disabled || loading}
      className={`rounded-pill ${width} submit-button mr-2 ${color}`}
      style={styles}
    >
      {loading ? "" : title}
      {loading ? (
        <span
          className="spinner-border spinner-border-sm mt-0"
          role="status"
          aria-hidden="true"
        />
      ) : (
        logo
      )}
    </CButton>
  );
};

export const getParentData = (dataM) =>
  dataM?.filter(({ invGroupId }) => !!!invGroupId);

export const getAddOnData = (dataM) =>
  dataM?.filter(({ invGroupId }) => !!invGroupId);

export const asyncSearchCreatable = (
  values,
  setFieldValue,
  promiseExtractProductOptions,
  index,
  width,
  isValidNewOption,
  colType
) => {
  const inputStyle = {
    container: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: width ?? "200px",
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  var dataParent = getParentData(values?.dataM);

  return (
    <AsyncCreatableSelect
      value={{
        value: dataParent?.[index]?.invProductId,
        label: dataParent?.[index]?.[colType ?? "name"],
      }}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      styles={inputStyle}
      cacheOptions
      defaultOptions
      isDisabled={dataParent?.[index]?.editableName === false}
      loadOptions={promiseExtractProductOptions}
      placeholder="Search product by sku / name"
      key={"searchCreateProduct_" + index}
      isValidNewOption={() => isValidNewOption ?? true}
      onCreateOption={(itemName) => {
        var newData = {
          invFeId: uuid(),
          sku: "-",
          name: itemName,
          description: "-",
          priceBuyPerUnit: 0,
          priceSellPerUnit: 0,
          stockToCount: 1,
          subTotal: 0,
        };

        dataParent[index] = newData;

        setFieldValue("dataM", [...dataParent, ...getAddOnData(values?.dataM)]);
        updateTransactionTotalValue(values?.dataM, {
          setFieldValue,
          values,
        });
      }}
      onChange={(k) => {
        var moq = k?.moq ?? 1;
        var priceSellPerUnit = k?.priceSellPerUnit ?? 0;
        var priceBuyPerUnit = k?.priceBuyPerUnit ?? 0;
        const newData = {
          invFeId: uuid(),
          invProductId: k?.value,
          InvProductLogId: k?.invProductLogId,
          paid: k?.paid,
          sku: k?.sku,
          name: k?.name,
          description: k.description ?? "-",
          stockToCount: moq,
          stockToCountProduct: 0,
          priceSellPerUnit: priceSellPerUnit,
          priceBuyPerUnit: priceBuyPerUnit,
          subTotal: values?.trxType
            ? moq * priceSellPerUnit
            : moq * priceBuyPerUnit,
          printerName: k?.printerName,
          printerId: k?.printerId,
          unPaidQuantity: k?.unPaidQuantity,
        };

        dataParent[index] = newData;
        setFieldValue("dataM", [...dataParent, ...getAddOnData(values?.dataM)]);
        updateTransactionTotalValue(
          [...dataParent, ...getAddOnData(values?.dataM)],
          {
            setFieldValue,
            values,
          }
        );
      }}
    />
  );
};

export const searchModel = (
  getModelIsLoading,
  setFieldValue,
  promiseExtractKeyValueOptions,
  values
) => {
  const inputStyle = {
    control: (styles) => {
      return {
        ...styles,
        border: "0",
        borderRadius: "0%",
        borderBottom: "1px solid #d8dbe0",
        "&:hover": { borderColor: "none" },
        boxShadow: "none",
        minHeight: 0,
      };
    },
  };

  return (
    <AsyncCreatableSelect
      value={
        values?.model?.keyValueId
          ? { value: values?.model?.keyValueId, label: values?.model?.label }
          : values?.model?.label
          ? { label: values?.model?.label }
          : ""
      }
      styles={inputStyle}
      className="mb-3"
      cacheOptions
      defaultOptions
      loadOptions={promiseExtractKeyValueOptions}
      placeholder="Search model "
      key="model"
      isLoading={getModelIsLoading}
      name="model"
      id="model"
      onCreateOption={(modelName) => {
        setFieldValue("model", {
          klass: "InvProductEntity",
          key: "model",
          label: modelName,
        });
      }}
      onChange={async (k) => {
        const { value, label } = k;
        setFieldValue("model", {
          keyValueId: value,
          label: label,
        });
      }}
    />
  );
};

const asyncSelectInputStyle = {
  control: (styles) => {
    return {
      ...styles,
      border: "0",
      borderRadius: "0%",
      borderBottom: "1px solid #d8dbe0",
      "&:hover": { borderColor: "none" },
      boxShadow: "none",
      minHeight: 0,
    };
  },
};

export const SearchPrinter = (
  promiseExtractPrinterOptions,
  getPrinterIsLoading,
  setFieldValue,
  values
) => {
  const { t } = useTranslation();
  return (
    <AsyncSelect
      value={
        values?.printer
          ? { value: values?.printer, label: values?.printerName }
          : ""
      }
      styles={asyncSelectInputStyle}
      className="mb-3"
      cacheOptions
      defaultOptions
      loadOptions={promiseExtractPrinterOptions}
      placeholder={t("Search by printer name")}
      key="printer"
      isLoading={getPrinterIsLoading}
      name="printer"
      id="printer"
      onChange={async (k) => {
        const { value, label } = k;
        setFieldValue("printer", value);
        setFieldValue("printerName", label);
      }}
    />
  );
};

export const InvoiceColumnFilterSlot = (
  setSearch,
  search,
  configType,
  transactionType = "INVOICE"
) => {
  const { t } = useTranslation();
  const projects = useSelector((state) => state?.appContext);
  var isDarkMode = useSelector((state) => state.darkMode);
  const asysnInputStyle = {
    control: (styles) => {
      return {
        ...styles,
        height: 35,
        minHeight: 35,
        borderColor: isDarkMode ? "#282933" : "#d8dbe0",
        backgroundColor: isDarkMode && "#282933",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderColor: isDarkMode ? "#282933" : "#d8dbe0",
        backgroundColor: isDarkMode ? "#282933" : "white",
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        borderColor: isDarkMode ? (isFocused ? "grey" : "#282933") : "#d8dbe0",
        backgroundColor: isDarkMode
          ? isFocused
            ? "grey"
            : "#282933"
          : "white",
      };
    },
    container: (styles) => {
      return {
        ...styles,
        height: "calc(1.5em + 0.75rem + 2px)",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1.5",
      };
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        display: "none",
      };
    },
  };

  const [getProjectInfo, { isLoading: isGetProjectLoading }] =
    ProjectListMutate();
  const [getCategoryInfo, { isLoading: isGetCategoryLoading }] =
    CategoryListMutate([
      { key: "configType", operation: ":", value: configType },
      // {
      //   key: "project",
      //   operation: ":",
      //   value: formatFilter(projects),
      // },
    ]);

  const filterInput = (statusType, placeHolder, operation) => (
    <CInput
      className="col-input"
      placeholder={placeHolder}
      onChange={(e) => {
        var { value } = e.target;
        onFilterChangeHandler(
          search,
          setSearch,
          statusType,
          value,
          operation ?? ":"
        );
      }}
    ></CInput>
  );

  const filterSelectInput = (
    statusType,
    placeHolder,
    optionService,
    keyName,
    labelName,
    labelNameExtra,
    loader
  ) => {
    const optionLoader = (search) =>
      optionService(search).then((response) =>
        response?.data?.result?.map((option) => {
          return {
            value: option[keyName],
            label:
              option[labelName] +
              (option[labelNameExtra]
                ? " (" + option[labelNameExtra] + ")"
                : ""),
          };
        })
      );

    return (
      <AsyncSelect
        styles={asysnInputStyle}
        isClearable={true}
        defaultOptions
        loadOptions={optionLoader}
        placeholder={placeHolder}
        key={statusType}
        isLoading={loader}
        name={statusType}
        id={statusType}
        onChange={(k) => {
          onFilterChangeHandler(search, setSearch, statusType, k?.value);
        }}
      />
    );
  };

  const filterSelect = (
    statusType,
    placeHolder,
    options,
    keyName,
    labelName,
    labelNameExtra,
    tooltip
  ) => {
    return (
      <CSelect
        custom
        className="col-input"
        name={statusType}
        id={statusType}
        onChange={(e) => {
          const { value } = e.target;
          onFilterChangeHandler(search, setSearch, statusType, value);
        }}
        style={{
          MozAppearance: "none",
          WebkitAppearance: "none",
          appearance: "none",
          backgroundImage: "none",
        }}
      >
        <option key="" value="" selected>
          {placeHolder}
        </option>
        {options.map((option) => {
          return (
            <option
              key={option[keyName]}
              value={option[keyName]}
              title={option[tooltip]}
            >
              {t(option[labelName])}
            </option>
          );
        })}
      </CSelect>
    );
  };

  return {
    transactionDate: (
      <DateRangePickerStock
        setSearch={setSearch}
        search={search}
        statusType="transactionDate"
      />
    ),
    createdDate: (
      <DateRangePickerStock
        setSearch={setSearch}
        search={search}
        statusType="createdDate"
      />
    ),
    modifiedDate: (
      <DateRangePickerStock
        setSearch={setSearch}
        search={search}
        statusType="modifiedDate"
      />
    ),
    dueDate: (
      <DateRangePickerStock
        setSearch={setSearch}
        search={search}
        status="dueDate"
      />
    ),
    billId:
      transactionType != "RECURRING" &&
      filterInput("billId", t("Invoice No") + ".."),
    partner:
      transactionType != "RECURRING" &&
      filterInput(
        "partnerName",
        configType == "SALES" ||
          configType ==
            "INCOMESpAcEOTHERarrCAPITALSpAcEDEPTHarrCAPITALSpAcEEQUITYarrCAPITALSpAcEEQUITY"
          ? t("Customer name..") + ".."
          : t("Company name..") + "..",
        "~"
      ),
    billReferenceId: filterInput("billReferenceId", t("Quotation No") + ".."),
    orderNo: filterInput("orderNo", t("Order No") + ".."),
    account: filterSelectInput(
      "projectConfig",
      t("accounts") + "..",
      getCategoryInfo,
      "projectConfigId",
      "category",
      "projectTitle",
      isGetCategoryLoading
    ),
    projectName: filterSelectInput(
      "project",
      t("Project") + "..",
      getProjectInfo,
      "projectId",
      "projectTitle",
      null,
      isGetProjectLoading
    ),
    status: filterSelect(
      "status",
      "Status..",
      transactionType == "QUOTATION"
        ? quotationStatusConstant
        : transactionType == "ORDER"
        ? purchaseSaleOrderStatusConstant
        : transactionType == "RECURRING"
        ? recurringStatusConstant
        : invoiceStatusConstant,
      "key",
      "label",
      null,
      "tooltip"
    ),
    billStatus: filterSelect(
      "status",
      "Status..",
      transactionType == "QUOTATION"
        ? quotationStatusConstant
        : transactionType == "ORDER"
        ? purchaseSaleOrderStatusConstant
        : transactionType == "RECURRING"
        ? recurringStatusConstant
        : invoiceStatusConstant,
      "key",
      "label",
      null,
      "tooltip"
    ),
  };
};

export const ProductColumnFilterSlot = (setSearch, search) => {
  const { t } = useTranslation();
  const asysnInputStyle = {
    control: (styles) => {
      return {
        ...styles,
        height: 35,
        minHeight: 35,
        borderColor: "#d8dbe0",
      };
    },
    container: (styles) => {
      return {
        ...styles,
        height: "calc(1.5em + 0.75rem + 2px)",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1.5",
      };
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        display: "none",
      };
    },
  };
  const [getInventoryInfo, { isGetInventoryLoading }] = InventoryListMutate();
  const [getProductCategaryInfo, { isGetProductCategaryLoading }] =
    ProductCategoryMutate();
  const { data: brandCategoryInfo } = ProductBrandCategory();
  const filterInput = (statusType, placeHolder, operation) => (
    <CInput
      className="col-input"
      placeholder={placeHolder}
      onChange={(e) => {
        var { value } = e.target;
        onFilterChangeHandler(search, setSearch, statusType, value, operation);
      }}
    ></CInput>
  );

  const filterSelectInput = (
    statusType,
    placeHolder,
    optionService,
    keyName,
    labelName,
    labelNameExtra,
    loader
  ) => {
    const optionLoader = (search) =>
      optionService(search).then((response) =>
        response?.data?.result?.map((option) => {
          return {
            value: option[keyName],
            label:
              option[labelName] +
              (option[labelNameExtra]
                ? " (" + option[labelNameExtra] + ")"
                : ""),
          };
        })
      );

    return (
      <AsyncSelect
        styles={asysnInputStyle}
        cacheOptions
        isClearable={true}
        defaultOptions
        loadOptions={optionLoader}
        placeholder={placeHolder}
        key={statusType}
        isLoading={loader}
        name={statusType}
        id={statusType}
        onChange={(k) => {
          onFilterChangeHandler(search, setSearch, statusType, k?.value);
        }}
      />
    );
  };

  const filterSelect = (
    statusType,
    placeHolder,
    options,
    keyName,
    labelName,
    labelNameExtra,
    tooltip
  ) => {
    return (
      <CSelect
        custom
        className="col-input"
        name={statusType}
        id={statusType}
        onChange={(e) => {
          const { value } = e.target;
          onFilterChangeHandler(search, setSearch, statusType, value);
        }}
        style={{
          MozAppearance: "none",
          WebkitAppearance: "none",
          appearance: "none",
          backgroundImage: "none",
        }}
      >
        <option key="" value="" selected>
          {placeHolder}
        </option>
        {options?.map((option) => {
          return (
            <option
              key={option[keyName]}
              value={formatFilter(option[keyName])}
              title={option[tooltip]}
            >
              {t(option[labelName])}
            </option>
          );
        })}
      </CSelect>
    );
  };

  return {
    inventoryName: filterSelectInput(
      "inventory",
      t("Inventory") + "..",
      getInventoryInfo,
      "inventoryId",
      "name",
      null,
      isGetInventoryLoading
    ),
    name: filterInput("name", t("Product") + "..", "~"),
    brand: filterSelect(
      "brand",
      "Brand..",
      brandCategoryInfo?.data?.brand
        ?.filter((d) => !!d)
        ?.map((option) => {
          return { key: option, label: option };
        }),
      "key",
      "label",
      null,
      "tooltip"
    ),
    category: filterSelect(
      "category",
      "Category..",
      brandCategoryInfo?.data?.category
        ?.filter((d) => !!d)
        ?.map((option) => {
          return { key: option?.trim(), label: option };
        }),
      "key",
      "label",
      null,
      "tooltip"
    ),
    model: filterSelectInput(
      "model",
      t("model") + "..",
      getProductCategaryInfo,
      "label",
      "label",
      null,
      isGetProductCategaryLoading
    ),
  };
};

export const ConfigTypeFilter = (setConfigType) => {
  const { t } = useTranslation();

  return (
    <CCol sm="2">
      <CLabel>{t("Account type")}:</CLabel>
      <CSelect
        custom
        name="costType"
        id="costType"
        onChange={async (e) => {
          const { value } = e.target;
          setConfigType(value);
        }}
      >
        <option
          key="OTHERSarrREFUNDarrCASHOUT"
          value="OTHERSarrREFUNDarrCASHOUT"
          selected
        >
          {t("ALL BELOW")}
        </option>
        {projectConfigType.map((configType) => {
          if (!["OTHERS", "REFUND", "CASHOUT"].includes(configType.key)) return;
          return (
            <option
              key={configType.key}
              value={configType.key}
              title={configType.tooltip}
            >
              {t(configType.label)}
            </option>
          );
        })}
      </CSelect>
    </CCol>
  );
};

export const OrderTypeFilter = (setOrderType) => {
  const { t } = useTranslation();

  return (
    <CCol sm="2">
      <CLabel>{t("Order type")} :</CLabel>
      <CSelect
        custom
        name="orderType"
        id="orderType"
        onChange={async (e) => {
          const { value } = e.target;
          setOrderType(value);
        }}
      >
        <option key="PURCHASEarrSALE" value="PURCHASEarrSALE" selected>
          {t("ALL BELOW")}
        </option>
        {orderType.map((orderType) => {
          return (
            <option
              key={orderType.key}
              value={orderType.key}
              title={orderType.tooltip}
            >
              {t(orderType.label)}
            </option>
          );
        })}
      </CSelect>
    </CCol>
  );
};

export const onFilterChangeHandler = (
  search,
  setSearch,
  statusType,
  value,
  operation
) => {
  const newFilter = Object.assign([], search);
  const index = search?.findIndex(
    (filter) =>
      filter?.key == statusType && filter?.operation == (operation ?? ":")
  );
  if (index < 0) {
    newFilter.push({
      key: statusType,
      operation: operation ?? ":",
      value: typeof value === "string" ? value?.replaceAll("-", "DasH") : value,
    });
  } else {
    newFilter[index] = {
      key: statusType,
      operation: operation ?? ":",
      value: typeof value === "string" ? value?.replaceAll("-", "DasH") : value,
    };
  }
  setSearch(newFilter);
};

export const toggleDetails = (index, showInput, setShowInputs) => {
  if (showInput.includes(index)) {
    setShowInputs([]);
  } else {
    setShowInputs([index]);
  }
};

export const formatFilter = (va) => {
  // if the numbere is date then return as it is
  if (typeof va != "string" && !Array.isArray(va)) return va;
  if (typeof va == "number") return va;
  if (Array.isArray(va)) {
    return encodeURIComponent(
      va
        ?.map((p) => p?.value)
        ?.join("arr")
        ?.replaceAll("-", "DasH")
        ?.replaceAll(" ", "SpAcE")
    );
  }

  return encodeURIComponent(
    va?.replaceAll("-", "DasH")?.replaceAll(" ", "SpAcE")
  );
};

export const InvoiceSummary = (
  search,
  configType,
  setIsLoading,
  print = false
) => {
  const { t } = useTranslation();
  const projects = useSelector((state) => state?.appContext);

  const { isLoading, data: summary } = useQuery(
    ["TRANSACTION_SUMMARY_FETCHALL_API", { ...search, configType }],
    () =>
      get({
        path: searchQueryBuilder(TRANSACTION_SUMMARY_FETCHALL_API, search),
      }),
    {
      onSuccess: (resp) => {
        if (setIsLoading) setIsLoading(false);
      },
    }
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {!print && (
        <CRow className="mt-2">
          <CCol>
            <div className="float-right">
              <CTooltip
                content={t(
                  "Default date is current year 1st january - 31 december. Filter by issue date + account to get more specific details"
                )}
              >
                <AiOutlineExclamationCircle />
              </CTooltip>
            </div>
          </CCol>
        </CRow>
      )}
      <CRow className="mt-2">
        <CCol xs="6">
          <CLabel>{t("Paid")}</CLabel>
        </CCol>
        <CCol>
          <CLabel className="float-right">
            RM {formatDouble(summary?.data?.paid)}
          </CLabel>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="6">
          <CLabel>{t("Unpaid")}</CLabel>
        </CCol>
        <CCol>
          <CLabel className="float-right" style={{ color: "#900C3F" }}>
            RM {formatDouble(summary?.data?.unpaid)}
          </CLabel>
        </CCol>
      </CRow>
      <ColoredLine color="#D6D6D6" />
      <CRow>
        <CCol xs="6">
          <CLabel>
            <strong>{t("Total")}</strong>
          </CLabel>
        </CCol>
        <CCol>
          <CLabel className="float-right">
            <strong>RM {formatDouble(summary?.data?.total)}</strong>
          </CLabel>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="6">
          <CLabel>{t("Overpay")}</CLabel>
        </CCol>
        <CCol>
          <CLabel className="float-right" style={{ color: "#900C3F" }}>
            RM {formatDouble(summary?.data?.overpay)}
          </CLabel>
        </CCol>
      </CRow>
    </>
  );
};

export const dropdownMenuModifiers = [
  {
    name: "offset",
    options: {
      offset: [10, 10],
    },
  },
  {
    name: "flip",
    options: {
      fallbackPlacements: ["bottom", "top", "left"],
    },
  },
];

export const removeColumnIfPrint = (columns, columnToRemove = [], print) => {
  if (!print) return columns;

  // return columns
  //   ?.filter((c) => !columnToRemove?.includes(c.key))
  //   .map((p) => {
  //     p["_style"] = {};
  //     return p;
  //   });
  return columns?.filter((c) => !columnToRemove?.includes(c.key));
};

export const ReportHeader = ({
  companyName,
  ssm,
  branchTitle,
  address,
  telNo,
  email,
  date,
  title,
  subTitle,
}) => (
  <CRow className="mb-3 mx-3">
    <CCol sm="8">
      <CRow>
        <CCol sm="4" className="text-center" style={{ display: "contents" }}>
          <img
            style={{
              maxHeight: "95px",
              maxWidth: "170px",
              marginBottom: "5px",
              marginRight: "10px",
              float: "left",
            }}
            src={getBaseUrl("eas") + "/image/logo/fetch"}
            alt={companyName}
          />
        </CCol>
        <CCol>
          {companyName ? (
            <h4 style={{ color: "black", lineHeight: "17px" }}>
              {companyName}{" "}
              <span style={{ fontSize: "12px" }} className="text-muted">
                {ssm}
              </span>
            </h4>
          ) : null}
          {branchTitle && (
            <p
              style={{ whiteSpace: "pre-line", lineHeight: "17px" }}
              className="my-0 font-weight-bold"
            >
              {branchTitle}
            </p>
          )}
          {address && (
            <p
              style={{ whiteSpace: "pre-line", lineHeight: "17px" }}
              className="my-0 font-weight-bold"
            >
              {address}
            </p>
          )}

          {telNo && (
            <p className="my-0 font-weight-bold" style={{ lineHeight: "17px" }}>
              Tel: {telNo}
            </p>
          )}
          {email && (
            <p className="my-0 font-weight-bold" style={{ lineHeight: "17px" }}>
              {email}
            </p>
          )}
        </CCol>
      </CRow>
    </CCol>
    <CCol sm="4" style={{ textAlign: "right" }}>
      <CRow>
        <CCol>
          <h4 style={{ color: "black" }}>{title}</h4>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <h5 style={{ color: "black" }}>{subTitle}</h5>
        </CCol>
      </CRow>
      <CRow className="flex-column">
        <CCol>
          <p>Day: {date}</p>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
);

export const InvoicePaymentMethodSummary = (
  setLoading,
  print = false,
  projects = [],
  search
) => {
  // console.log(search);
  const [
    generateCloseout,
    { isLoading: isLoadingGenerateReportCloseout, data: closeoutReportInfo },
  ] = GetPaymentSummary();

  useLayoutEffect(() => {
    generateCloseout(search).then(() => {
      if (setLoading) setLoading(false);
    });
  }, search);

  if (isLoadingGenerateReportCloseout) return <Loader />;
  return (
    <>
      <CRow className="mt-2">
        <CCol xs="8">
          <CLabel className="font-weight-bold">Payment Methods</CLabel>
        </CCol>
        <CCol>
          <CLabel className="float-right font-weight-bold">Amount</CLabel>
        </CCol>
      </CRow>
      {Object.keys(closeoutReportInfo?.data?.simpleResult ?? {}).map(
        (item, index) => {
          return (
            <CRow>
              <CCol xs="8">
                <CLabel>{item}</CLabel>
              </CCol>
              <CCol>
                <CLabel className="float-right">
                  RM{formatDouble(closeoutReportInfo?.data?.simpleResult[item])}
                </CLabel>
              </CCol>
            </CRow>
          );
        }
      )}
    </>
  );
};
