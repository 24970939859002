import "font-awesome/css/font-awesome.min.css";
import React, { Suspense } from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { install } from "resize-observer";
import App from "./App";
import "./assets/fonts/sofia-pro-cdnfonts/Sofia-Pro-Regular-Az.otf";
import { icons } from "./assets/icons";
import "./i18n";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./stores/store";
import { Loader, loader } from "./utils/tableUtils";

if (!window.ResizeObserver) install();

ReactGA.initialize("G-6FCFCWT2FL");

React.icons = icons;
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Suspense fallback={<Loader />}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={loader} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
