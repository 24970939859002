import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { useSelector } from "react-redux";
import { MomentLocale } from "src/localization/momentLocale";
import {
  handleInfoToast,
  handleSucces,
  safeQueryBuilder,
  searchQueryBuilder,
} from "src/utils/formUtils";
import {
  formatFilter,
  tableBasicParams,
  tableBasicParamsWithRow,
} from "src/utils/tableUtils";
import {
  deleteEle,
  get,
  getBaseUrl,
  getInventory,
  patch,
  post,
} from "./dataService";
import {
  ACCOUNT_DELETE_API,
  ANAYLYTIC_SALES_TREND_API,
  ANAYLYTIC_SALES_YEARLY_TREND_API,
  BANK_API,
  BANK_SYNC_API,
  CATEGORY_API,
  CLOSING_REPORT,
  DELETE_FILE,
  DETAIL_TRANSACTION_ACCOUNT_REPORT,
  DOWNLOAD_INVOICES,
  DOWNLOAD_TEMPLATE,
  FETCH_FILE,
  INVENTORY_API,
  KEY_VALUE_FETCH_ALL_API,
  ORDER_FETCH_ALL_API,
  ORDER_GENERATE_INVOICE,
  PARTNER_API,
  PARTNER_GROUP_API,
  PAYMENT_TRANSACTION_CREATE_API,
  PAYMENT_TRANSACTION_DELETE_API,
  PAYMENT_TRANSACTION_FETCH_ALL_API,
  PAYMENT_TRANSACTION_SUMMARY,
  PAYMENT_TRANSACTION_UPDATE_API,
  PRODUCT_API,
  PRODUCT_CONCILIATION_FETCH_ALL_API,
  PRODUCT_CONCILIATION_FETCH_API,
  PRODUCT_FETCH_BRAND_CATEGORY_API,
  PRODUCT_SKU_BARCODE_API,
  PROJECT_API,
  RECURRING_UPDATE_API,
  REPORT_BALANCE_SHEET,
  REPORT_CASHFLOW,
  REPORT_CLOSEOUT_REPORT,
  REPORT_INCOME_STATEMENT,
  REPORT_LEDGER,
  REPORT_STAFF_PERFORMANCE,
  REPORT_STAFF_PERFORMANCE_DETAILS,
  REPORT_STATEMENT_OF_ACCOUNT,
  REPORT_TRIAL_BALANCE,
  SETTING_CREATE_API,
  SETTING_FETCH_ALL_API,
  SETTING_UPDATE_API,
  TRANSACTION_API,
  TRANSACTION_DELETE_API,
  TRANSACTION_FETCH_API,
  TRANSACTION_GENERATE_QUOTATION_API,
  TRANSACTION_NEW_API,
  TRANSACTION_UPDATE_API,
  UPLOAD_TEMPLATE,
  USER_CURRENT_API,
} from "./pathRequest";
var FileSaver = require("file-saver");

export const DeleteAccount = () => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(() =>
    deleteEle({
      path: ACCOUNT_DELETE_API,
    })
  );
};

export const UserInfo = (callback) => CompanyInfo(callback);

export const CompanyInfo = (callback) =>
  useQuery(
    ["USER_CURRENT_API"],
    () =>
      get({
        path: USER_CURRENT_API,
      }),
    {
      keepPreviousData: true,
      cacheTime: 600000,
      staleTime: 600000,
      retry: false,
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
    }
  );

export const ProjectList = (callback) => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    ["PROJECT_LIST"],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PROJECT_API,
            tableBasicParamsWithRow(0, "createdDate", "false", 1000)
          ),
          [
            { key: "configType", operation: ":", value: "PROJECT" },
            { key: "id", operation: ":", value: formatFilter(projects) },
          ]
        ),
      }),
    {
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const ProjectListMutate = () => {
  return useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PROJECT_API,
          tableBasicParamsWithRow(0, "createdDate", "false", 1000)
        ),
        [
          { key: "configType", operation: ":", value: "PROJECT" },
          { key: "projectTitle", operation: "~", value: search },
        ]
      ),
    })
  );
};

export const CategoryListMutate = (extra) =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          CATEGORY_API,
          tableBasicParamsWithRow(0, "createdDate", "false", 1000)
        ),
        [{ key: "category", operation: "~", value: search }].concat(extra ?? [])
      ),
    })
  );

export const CategoryListByProject = ({ page, sortBy, isAsc, projectId }) =>
  useQuery(["CATEGORY_API", { page, sortBy, isAsc, projectId }], () =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(CATEGORY_API, tableBasicParams(page, sortBy, isAsc)),
        [
          {
            key: "project",
            operation: ":",
            value: projectId?.replaceAll("-", "DasH"),
          },
        ]
      ),
    })
  );

export const CategoryListByProjectMutate = (search, extra) =>
  useMutation(
    (projectId) =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            CATEGORY_API,
            tableBasicParamsWithRow(0, "createdDate", "false", 1000)
          ),
          [
            {
              key: "project",
              operation: ":",
              value:
                search?.replaceAll("-", "DasH") ||
                projectId?.replaceAll("-", "DasH"),
            },
          ].concat(extra ?? [])
        ),
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: "Infinity",
    }
  );

export const BankList = () =>
  useQuery(
    "BANK_API",
    () =>
      get({
        path: BANK_API,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

export const CreateTransaction = (callback) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(
    (form) =>
      post({
        path: TRANSACTION_NEW_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        if (callback) {
          callback();
        } else {
          queryCache.refetchQueries("INVOICE_API", { lazy: true });
          queryCache.invalidateQueries("BANK_API", { lazy: true });
          handleSucces(t("saved"), t("success"));
        }
      },
    }
  );
};

export const GenerateQuotation = (callback) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(
    (form) =>
      post({
        path: TRANSACTION_GENERATE_QUOTATION_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        if (callback) {
          callback();
        } else {
          queryCache.refetchQueries("INVOICE_API", { lazy: true });
          queryCache.invalidateQueries("BANK_API", { lazy: true });
          handleSucces(t("saved"), t("success"));
        }
      },
    }
  );
};

export const GetTransaction = (transactionId, callBack = null) => {
  if (!transactionId) {
    return { data: null };
  }
  return useQuery(
    ["TRANSACTION_FETCH_API", transactionId],
    () =>
      get({
        path: safeQueryBuilder(TRANSACTION_FETCH_API, { id: transactionId }),
      }),
    {
      onSuccess: (transactionInfo) => {
        if (callBack) callBack(transactionInfo);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const UpdateTransaction = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: TRANSACTION_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const DeleteTransaction = (transactionId) =>
  useMutation(() =>
    deleteEle({
      path: safeQueryBuilder(TRANSACTION_DELETE_API, { id: transactionId }),
    })
  );

export const PaymentTransactionList = ({
  page,
  sortBy,
  isAsc,
  startDateFilter,
  endDateFilter,
  transactionId,
  type = "transaction",
  extra,
  row = 10,
  execute = true,
}) =>
  useQuery(
    [
      "PAYMENT_TRANSACTION_FETCH_ALL_API",
      {
        page,
        sortBy,
        isAsc,
        startDateFilter,
        endDateFilter,
        transactionId,
        ...extra,
      },
    ],
    () => {
      if (!execute) return Promise.resolve();

      return get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PAYMENT_TRANSACTION_FETCH_ALL_API,
            Object.assign(tableBasicParamsWithRow(page, sortBy, isAsc, row), {
              id: transactionId,
              type: type,
            })
          ),
          [
            { key: "paymentDate", operation: ">=", value: startDateFilter },
            { key: "paymentDate", operation: "<=", value: endDateFilter },
          ].concat(extra ?? [])
        ),
      });
    }
  );

export const CreatePaymentTransaction = (callback) =>
  useMutation(
    (form) =>
      post({
        path: PAYMENT_TRANSACTION_CREATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const UpdatePaymentTransaction = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: PAYMENT_TRANSACTION_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const DeletePayment = () => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(
    (paymentTransactionId) =>
      deleteEle({
        path: safeQueryBuilder(PAYMENT_TRANSACTION_DELETE_API, {
          id: paymentTransactionId,
        }),
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries("PAYMENT_TRANSACTION_FETCH_ALL_API", {
          lazy: true,
        });
        queryCache.invalidateQueries("TRANSACTION_FETCH_API", { lazy: true });
        queryCache.refetchQueries("TRANSACTION_API", { lazy: true });
        handleSucces(t("saved"), t("success"));
      },
    }
  );
};

export const GetBankList = () => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    "BANK_API",
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            BANK_API,
            tableBasicParamsWithRow(0, "name", true, 50)
          ),
          [{ key: "project", operation: ":", value: formatFilter(projects) }]
        ),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const BankSync = () => {
  const queryCache = useQueryCache();
  return useMutation(
    () =>
      get({
        path: BANK_SYNC_API,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("BANK_API", { lazy: true });
      },
    }
  );
};

export const SearchCustomerMutate = (type) =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(PARTNER_API, tableBasicParams(1, "name", false)),
        [
          { key: "email", operation: "~", value: search },
          { key: "telNo", operation: ":", value: search },
          { key: "name", operation: "~", value: search },
          { key: "type", operation: ":", value: type },
        ]
      ),
    })
  );

export const SearchStaffMutate = () =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(PARTNER_API, tableBasicParams(1, "name", false)),
        [
          { key: "email", operation: "~", value: search },
          { key: "telNo", operation: "~", value: search },
          { key: "name", operation: "~", value: search },
          { key: "type", operation: ":", value: "STAFF" },
        ]
      ),
    })
  );

export const PartnerGroupList = (callback) =>
  useQuery(
    ["GROUP_API"],
    () =>
      get({
        path: PARTNER_GROUP_API,
      }),
    {
      onSuccess: (response) => callback(response),
      refetchOnWindowFocus: false,
    }
  );

export const SearchProduct = (searchBy = "name") =>
  useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(PRODUCT_API, tableBasicParams(1, searchBy, false)),
        [
          {
            key: searchBy,
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
        ]
      ),
    })
  );

export const SearchProductByNameAndSku = () =>
  useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(PRODUCT_API, tableBasicParams(1, "name", false)),
        [
          {
            key: "name",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "sku",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
        ]
      ),
    })
  );
export const SearchProductByBarcode = () =>
  useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PRODUCT_SKU_BARCODE_API,
          tableBasicParams(1, "barcodeId", false)
        ),
        [
          {
            key: "barcodeId",
            operation: ":",
            value: search ? search.replaceAll(" ", "SpAcE") : " ",
          },
        ]
      ),
    })
  );

export const Print = (url) => {
  const { t } = useTranslation();
  return useMutation(
    (form) =>
      axios.post(url, form, {
        timeout: 2000,
      }),
    {
      onSuccess: () => {
        handleSucces("Print " + t("success"), t("success"));
      },
    }
  );
};

export const GenerateCustomerLedger = () =>
  useMutation(({ projectId, startDate, endDate, filter }) =>
    get({
      path: safeQueryBuilder(REPORT_LEDGER, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        ledgerType: "customerLedger",
        filter: filter,
      }),
    })
  );

export const GenerateClosingReport = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(CLOSING_REPORT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateDetailTransactionAccountReport = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(DETAIL_TRANSACTION_ACCOUNT_REPORT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateStatementOfAccount = () =>
  useMutation(({ projectId, startDate, endDate, partner }) =>
    get({
      path: safeQueryBuilder(REPORT_STATEMENT_OF_ACCOUNT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        partnerId: partner?.partnerId,
      }),
    })
  );

export const GenerateReportStaffPerformance = () =>
  useMutation(({ projectId, startDate, endDate, self }) =>
    get({
      path: safeQueryBuilder(REPORT_STAFF_PERFORMANCE, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        self: self,
      }),
    })
  );

export const GenerateReportStaffPerformanceDetails = () =>
  useMutation(({ projectId, startDate, endDate, staffId }) =>
    get({
      path: safeQueryBuilder(REPORT_STAFF_PERFORMANCE_DETAILS, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        staffId: staffId,
      }),
    })
  );

export const GenerateSupplierLedger = () =>
  useMutation(({ projectId, startDate, endDate, filter }) =>
    get({
      path: safeQueryBuilder(REPORT_LEDGER, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        ledgerType: "supplierLedger",
        filter: filter,
      }),
    })
  );

export const GenerateReportIncomeStatement = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_INCOME_STATEMENT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportCashflow = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_CASHFLOW, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportTrialBalance = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_TRIAL_BALANCE, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportBalanceSheet = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_BALANCE_SHEET, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const OrderGenerateInvoice = () =>
  useMutation((id) =>
    get({
      path: safeQueryBuilder(ORDER_GENERATE_INVOICE, { id: id }),
    })
  );

export const GetOrder = ({
  page,
  sortBy,
  isAsc,
  startDateFilter,
  endDateFilter,
  orderType,
  filterStatusType = "status",
  statusType,
}) =>
  useQuery(
    [
      "ORDER_FETCH_ALL_API",
      {
        page,
        sortBy,
        isAsc,
        startDateFilter,
        endDateFilter,
        orderType,
        filterStatusType,
        statusType,
      },
    ],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            ORDER_FETCH_ALL_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            { key: "createdDate", operation: ">=", value: startDateFilter },
            { key: "createdDate", operation: "<=", value: endDateFilter },
            { key: "type", operation: ":", value: orderType },
            { key: filterStatusType, operation: ":", value: statusType },
          ]
        ),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: "Infinity",
    }
  );

export const OrderSetting = (callback) => SettingConfig(callback);

export const SettingConfig = (callback) =>
  useQuery(
    "SETTING_FETCH_ALL_API",
    () =>
      get({
        path: safeQueryBuilder(SETTING_FETCH_ALL_API),
      }),
    {
      onSuccess: (data) => callback(data),
      refetchOnWindowFocus: false,
    }
  );

export const CreateSetting = (callback) =>
  useMutation(
    (form) =>
      post({
        path: SETTING_CREATE_API,
        formData: form,
      }),
    {
      onSuccess: () => callback(),
    }
  );

export const UpdateSetting = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: SETTING_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => callback(),
    }
  );

export const InventoryList = ({ page, sortBy, isAsc }) => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    ["INVENTORY_API", { page, sortBy, isAsc }],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            INVENTORY_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ]
        ),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const ProductBrandCategory = () => {
  return useQuery(
    ["PRODUCT_FETCH_BRAND_CATEGORY_API"],
    () =>
      getInventory({
        path: PRODUCT_FETCH_BRAND_CATEGORY_API,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const InventoryListMutate = () => {
  const projects = useSelector((state) => state?.appContext);
  return useMutation(
    (search) =>
      getInventory({
        path: searchQueryBuilder(
          INVENTORY_API,
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ].concat(search)
        ),
      }),
    {
      retry: false,
    }
  );
};

export const ProductCategoryMutate = () =>
  useMutation(
    (search) => {
      return getInventory({
        path: searchQueryBuilder(KEY_VALUE_FETCH_ALL_API, [
          { key: "klass", operation: ":", value: "InvProductEntity" },
          { key: "label", operation: "~", value: search },
        ]),
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: "Infinity",
    }
  );

export const ConciliationList = ({ productId, page, sortBy, isAsc }) =>
  useQuery(
    ["PRODUCT_CONCILIATION_FETCH_ALL_API", { productId, page, sortBy, isAsc }],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PRODUCT_CONCILIATION_FETCH_ALL_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            {
              key: "product",
              operation: ":",
              value: productId.replaceAll("-", "DasH"),
            },
          ]
        ),
      })
  );

export const GetConciliation = (invProductConciliationId) =>
  useQuery("PRODUCT_CONCILIATION_FETCH_API", () =>
    getInventory({
      path: PRODUCT_CONCILIATION_FETCH_API + "?id=" + invProductConciliationId,
    })
  );

export const DownloadTransactionTemplate = () => {
  var base = getBaseUrl("eas");

  return useMutation(() =>
    axios
      .get(base + DOWNLOAD_TEMPLATE, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, "transaction-template.csv");
      })
  );
};

export const UploadTransactionTemplate = (url) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation(
    (form) =>
      instance.post(base + (url ?? UPLOAD_TEMPLATE), form, { timeout: 600000 }),
    {
      onSuccess: () => {
        handleSucces(t("saved"), t("success"));
        queryCache.invalidateQueries("CATEGORY_API", { lazy: true });
        queryCache.invalidateQueries("TRANSACTION_API", { lazy: true });
        queryCache.invalidateQueries("PARTNER_API", { lazy: true });
      },

      onError: (error) => {
        var errorBody = error?.response?.data?.response;
        var message = errorBody?.status?.httpMessage;
        handleInfoToast(message);
      },
    }
  );
};

export const UploadImage = (url) => {
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation((form) => instance.patch(base + url, form), {
    onSuccess: () => {
      handleSucces(t("saved"), t("success"));
    },

    onError: (error) => {
      var errorBody = error?.response?.data?.response;
      var message = errorBody?.status?.httpMessage;
      handleInfoToast(message);
    },
  });
};

export const DownloadInvoices = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation(({ projectId, startDate, endDate }) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_INVOICES, {
          projectId: projectId,
          startDate: startDate,
          endDate: endDate,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `invoice-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const UploadFile = (url) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation(
    (form) => instance.post(base + url, form, { timeout: 600000 }),
    {
      onSuccess: () => {
        handleSucces(t("saved upload attachment"), t("success"));
        queryCache.invalidateQueries("CATEGORY_API", { lazy: true });
        queryCache.refetchQueries("PAYMENT_TRANSACTION_FETCH_ALL_API", {
          lazy: true,
        });
        queryCache.refetchQueries("PAYMENT_TRANSACTION_FETCH_API", {
          lazy: true,
        });
        queryCache.refetchQueries("TRANSACTION_API", { lazy: true });
        queryCache.refetchQueries("TRANSACTION_FETCH_API", { lazy: true });
      },

      onError: (error) => {
        var errorBody = error?.response?.data?.response;
        var message = errorBody?.status?.httpMessage;
        handleInfoToast(message);
      },
    }
  );
};

export const DownloadFile = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation((fileId) =>
    axios
      .get(safeQueryBuilder(base + FETCH_FILE + "?id=" + fileId), {
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0];
        let e = response.headers["content-disposition"].split(".");
        let extension = e[e.length - 1].split(";")[0];
        var name = `${filename}.${extension}`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
      .finally(() => {
        // console.log("finish");
      })
  );
};

export const DeleteFile = () =>
  useMutation((fileId) =>
    deleteEle({
      path: safeQueryBuilder(DELETE_FILE, { id: fileId }),
    })
  );

export const UpdateRecurring = () =>
  useMutation((form) =>
    patch({
      path: RECURRING_UPDATE_API,
      formData: form,
    })
  );

export const SettingListByType = ({ page, sortBy, isAsc, type }) =>
  useQuery(
    ["SETTING_FETCH_ALL_API", { page, sortBy, isAsc, type: type }],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            SETTING_FETCH_ALL_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [{ key: "type", operation: ":", value: type }]
        ),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

export const PartnerTransactionList = (
  cacheKey,
  { page, sortBy, isAsc, startDateFilter, endDateFilter, partnerId, extra = [] }
) =>
  useQuery(
    [
      cacheKey,
      {
        page,
        sortBy,
        isAsc,
        startDateFilter,
        endDateFilter,
        partnerId,
        ...extra,
      },
    ],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            TRANSACTION_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            {
              key: "partner",
              operation: ":",
              value: partnerId.replaceAll("-", "DasH"),
            },
            {
              key: "type",
              operation: ":",
              value: "INVOICE",
            },
          ].concat(extra ?? [])
        ),
      })
  );

export const GenerateReportCloseout = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_CLOSEOUT_REPORT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateSalesTrend = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(ANAYLYTIC_SALES_TREND_API, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateSalesYearlyTrend = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(ANAYLYTIC_SALES_YEARLY_TREND_API, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GetPaymentSummary = () =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(PAYMENT_TRANSACTION_SUMMARY, search),
    })
  );
