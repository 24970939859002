export const REGISTER_INVITED = "/account/signUp";
export const RESET_PASSWORD = "/account/resetPasswordLink";
export const RESEND_VERIFICATION_LINK = "/account/verify/resend";
export const REGISTER_TRIAL = "/account/signUp/admin/trial";
export const REGISTER_ADMIN = "/account/signUp/admin";
export const PROJECT_API = "/project/fetchAll";
export const PROJECT_YEAR_API = "/project/fetchAll/year";
export const PROJECT_FIND_API = "/project/find";
export const PROJECT_CLONE_API = "/project/clone";
export const PROJECT_NEW_API = "/project/create";
export const PROJECT_REPORT_API = "/project/statement";
export const PROJECT_REPORT_CASHFLOW_API = "/project/statement/cashflow";
export const PROJECT_FETCH_API = "/project/fetch";
export const PROJECT_DELETE_API = "/project/delete";
export const PROJECT_UPDATE_API = "/project/update";
export const PROJECT_SHARE_API = "/project/share";
export const TRANSACTION_SUMMARY_API = "/project/transaction/summary";
export const TRANSACTION_API = "/project/transaction/fetchAll";
export const TRANSACTION_SUMMARY_FETCHALL_API =
  "/project/transaction/fetchAll/summary";
export const TRANSACTION_NEW_API = "/project/transaction/create";
export const TRANSACTION_GENERATE_QUOTATION_API =
  "/project/transaction/create/quotation";
export const TRANSACTION_UPDATE_API = "/project/transaction/update";
export const TRANSACTION_FETCH_API = "/project/transaction/fetch";
export const TRANSACTION_DELETE_API = "/project/transaction/delete";
export const TRANSACTION_FETCH_PUBLIC_API = "/project/transaction/public";
export const CATEGORY_API = "/project/config/fetchAll";
export const CATEGORY_NEW_API = "/project/config/create";
export const CATEGORY_PARENT_API = "/project/config/fetchAll/parent";
export const CATEGORY_FETCH_API = "/project/config/fetch";
export const CATEGORY_UPDATE_API = "/project/config/update";
export const CATEGORY_DELETE_API = "/project/config/delete";
export const USER_INVITE_API = "/account/invite";
export const USER_API = "/account/users";
export const USER_CURRENT_API = "/account/userInfo";
export const USER_INFO_SETTING_API = "/account/userInfo/setting";
export const USER_UPDATE_API = "/account/updateUser";
export const USER_FETCH_API = "/account/user";
export const USER_DELETE_API = "/account/user/delete";
export const PRIVILEGE_UPDATE_API = "/account/privilege/update";
export const TAX_API = "/tax/fetchAll";
export const TAX_FETCH_API = "/tax/fetch";
export const TAX_CREATE_API = "/tax/create";
export const TAX_DELETE_API = "/tax/delete";
export const TAX_UPDATE_API = "/tax/update";
export const ANAYLYTIC_API = "/analytic/sales";
export const ANAYLYTIC__CASHFLOW_API = "/analytic/cashflow";
export const ANAYLYTIC_SUMMARY_API = "/analytic/summary";
export const ANAYLYTIC_PROFIT_AND_LOST_API = "/analytic/profit-lost";
export const ANAYLYTIC_REVENUE_COST_API = "/analytic/revenue-cost";
export const ANAYLYTIC_QUOTATION_API = "/analytic/quotation";
export const ANAYLYTIC_EXPENSE_API = "/analytic/expense";
export const ANAYLYTIC_BALANCE_SHEET_API = "/analytic/balance-sheet";
export const ANAYLYTIC_ORDER_API = "/analytic/order";
export const ANAYLYTIC_REVENUE_PRODUCT_API = "/analytic/revenue/product";
export const ANAYLYTIC_REVENUE_PARTNER_API = "/analytic/revenue/partner";
export const ANAYLYTIC_SALES_TREND_API = "/analytic/sales-trend";
export const ANAYLYTIC_SALES_YEARLY_TREND_API = "/analytic/sales-trend/yearly";
export const ADD_SUBSCRIPTION_API = "/subscribe/create";
export const RENEW_SUBSCRIPTION_API = "/payment/create/renew";
export const NEW_SUBSCRIPTION_API = "/create/payment";
export const SUBSCRIBE_FETCH_ALL = "/fetchAll";
export const SUBSCRIBE_FETCH_BY_USER = "/subscribe/status";
export const CAMPAIGN_FETCH_ALL = "/campaign/fetchAll";
export const CAMPAIGN_FETCH = "/campaign/fetch";
export const CAMPAIGN_CREATE = "/campaign/create";
export const CAMPAIGN_UPDATE = "/campaign/update";
export const PAYMENT_FETCH_ALL_BY_SUBSCRIBE = "/payment/fetchAll/subscription";
export const CODE_FETCH_ALL = "/code/fetchAll";
export const CODE_CAMPAIGN_FETCH_ALL = "/code/fetchAll/campaign";
export const CODE_CREATE = "/code/create";
export const CODE_FETCH = "/code/fetch";
export const BANK_API = "/bank/fetchAll";
export const BANK_SYNC_API = "/bank/sync";
export const BANK_FETCH_API = "/bank/fetch";
export const BANK_DELETE_API = "/bank/delete";
export const BANK_UPDATE_API = "/bank/update";
export const BANK_CREATE_API = "/bank/create";
export const BANK_TRANSFER_API = "/bank/transfer";
export const BILL_RETURN = "/create/payment/return";
export const PARTNER_API = "/partner/fetchAll";
export const PARTNER_NEW_API = "/partner/create";
export const PARTNER_UPDATE_API = "/partner/update";
export const PARTNER_FETCH_API = "/partner/fetch";
export const PARTNER_DELETE_API = "/partner/delete";
export const PARTNER_GROUP_NEW_API = "/partner/group/create";
export const PARTNER_GROUP_API = "/partner/group/fetchAll";
export const PARTNER_GROUP_FETCH_API = "/partner/group/fetch";
export const PARTNER_GROUP_UPDATE_API = "/partner/group/update";
export const PARTNER_GROUP_DELETE_API = "/partner/group/delete";
export const PRODUCT_FETCH_BRAND_CATEGORY_API = "/product/fetch/brand-category";
export const INVENTORY_API = "/fetchAll";
export const INVENTORY_CREATE_API = "/create";
export const INVENTORY_FETCH_API = "/fetch";
export const INVENTORY_UPDATE_API = "/update";
export const INVENTORY_DELETE_API = "/delete";
export const PRODUCT_SKU_API = "/product/sku/fetchAll";
export const PRODUCT_SKU_BARCODE_API = "/product/sku/fetchAll/barcode";
export const PRODUCT_SKU_CREATE_API = "/product/sku/create";
export const PRODUCT_SKU_DELETE_API = "/product/sku/delete";
export const PRODUCT_API = "/product/fetchAll";
export const PRODUCT_CREATE_API = "/product/create";
export const PRODUCT_FETCH_API = "/product/fetch";
export const PRODUCT_UPDATE_API = "/product/update";
export const PRODUCT_DELETE_API = "/product/delete";
export const PRODUCT_MOVEMENT = "/product/log/fetch";
export const PRODUCT_CONCILIATION_FETCH_ALL_API =
  "/product/conciliation/fetchAll";
export const PRODUCT_CONCILIATION_FETCH_API = "/product/conciliation/fetch";
export const PRODUCT_CONCILIATION_CREATE_API = "/product/conciliation/create";
export const PRODUCT_CONCILIATION_UPDATE_API = "/product/conciliation/update";
export const PRODUCT_CONCILIATION_DELETE_API = "/product/conciliation/delete";
export const SETTING_CREATE_API = "/setting/create";
export const SETTING_FETCH_ALL_API = "/setting/fetchAll";
export const SETTING_UPDATE_API = "/setting/update";
export const SETTING_DELETE_API = "/setting/delete";
export const ORDER_FETCH_ALL_API = "/order/fetchAll";
export const ORDER_FETCH_API = "/order/fetch";
export const ORDER_API = "/order/create";
export const ORDER_UPDATE_API = "/order/update";
export const ORDER_DELETE_API = "/order/delete";
export const ORDER_SUMMARY_API = "/order/summary";
export const ORDER_GENERATE_INVOICE = "/order/generate-invoice";
export const STRIPE_SELF_CARE = "/stripe/create-portal-session";
export const STRIPE_CHECKOUT_SESSION = "/stripe/create-checkout-session";
export const STRIPE_CHECKOUT_HARDWARE_SESSION =
  "/stripe/create-checkout-session/hardware";
export const KEY_VALUE_FETCH_ALL_API = "/key-value/fetchAll";
export const KEY_VALUE_CREATE_API = "/key-value/create";
export const KEY_VALUE_UPDATE_API = "/key-value/update";
export const KEY_VALUE_DELETE_API = "/key-value/delete";
export const NOTIFICATION_CREATE_API = "/notification/create";
export const NOTIFICATION_UPDATE_API = "/notification/update";
export const NOTIFICATION_DELETE_API = "/notification/delete";
export const NOTIFICATION_FETCH_API = "/notification/fetch";
export const NOTIFICATION_SEND_CUSTOMER_API = "/notification/notify/customer";
export const SHORT_LINK_CREATE_API = "/link/create";
export const SHORT_LINK_FETCH_API = "/link/fetch";
export const PAYMENT_TRANSACTION_CREATE_API =
  "/project/transaction/payment/create";
export const PAYMENT_TRANSACTION_UPDATE_API =
  "/project/transaction/payment/update";
export const PAYMENT_TRANSACTION_DELETE_API =
  "/project/transaction/payment/delete";
export const PAYMENT_TRANSACTION_FETCH_API =
  "/project/transaction/payment/fetch";
export const PAYMENT_TRANSACTION_FETCH_ALL_API =
  "/project/transaction/payment/fetchAll";
export const PAYMENT_TRANSACTION_SUMMARY =
  "/project/transaction/payment/summary";
export const REPORT_INCOME_STATEMENT = "/report/income-statement";
export const REPORT_CASHFLOW = "/report/cashflow";
export const REPORT_TRIAL_BALANCE = "/report/trial-balance";
export const REPORT_BALANCE_SHEET = "/report/balance-sheet";
export const REPORT_LEDGER = "/report/ledger";
export const CLOSING_REPORT = "/report/closing-report";
export const DETAIL_TRANSACTION_ACCOUNT_REPORT = "/report/detail-transaction";
export const REPORT_STATEMENT_OF_ACCOUNT = "/report/statement-of-account";
export const REPORT_STAFF_PERFORMANCE = "/report/staff-performance";
export const REPORT_STAFF_PERFORMANCE_DETAILS =
  "/report/staff-performance/detail";
export const DOWNLOAD_TEMPLATE = "/project/download/template";
export const UPLOAD_TEMPLATE = "/project/import";
export const UPLOAD_LOGO = "/image/logo/update";
export const DOWNLOAD_INVOICES = "/report/invoices";
export const UPLOAD_FILE = "/file/upload";
export const DELETE_FILE = "/file/delete";
export const FETCH_FILE = "/file/fetch";
export const RECURRING_API = "/recurring/fetchAll";
export const RECURRING_UPDATE_API = "/recurring/update";
export const UPLOAD_PARTNER = "/partner/import";
export const REPORT_CLOSEOUT_REPORT = "/report/closeout-report";
export const ACCOUNT_DELETE_API = "/account/delete";
