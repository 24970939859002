import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { formatDouble, formatFilter } from "./tableUtils";

export const useInput = (ele) => {
  const [value, setValue] = useState(ele);
  return {
    value,
    reset: () => setValue(""),
    setValue: (val) => setValue(val),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

export const useInputWithFunc = (ele, callBackfunct) => {
  const [value, setValue] = useState(ele);
  return {
    value,
    reset: () => setValue(""),
    setValue: (val) => {
      setValue(val);
      callBackfunct(val);
    },
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
        callBackfunct(event.target.value);
      },
    },
  };
};

export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function CheckPassword(inputtxt) {
  var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  if (inputtxt.match(passw)) {
    return true;
  } else {
    return false;
  }
}

export function stringLength(input, length) {
  if (input.length >= length) {
    return true;
  } else {
    return false;
  }
}

export const handleLoading = async () => {
  await Swal.fire({
    title: "Wait ...",
    onBeforeOpen() {
      Swal.showLoading();
    },
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};
export const handleSucces = async (successText, title) => {
  await Swal.fire({
    toast: true,
    position: "top-end",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
    icon: "success",
    width: 300,
    height: 200,
    title: title,
    text: successText,
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  }).then(() => {
    return Promise.resolve("success");
  });
};

export const handleInfoToast = async (text, title = "") => {
  await Swal.fire({
    toast: true,
    position: "top-end",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
    icon: "info",
    width: 300,
    height: 200,
    title: title,
    text: text,
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  }).then(() => {
    return Promise.resolve("success");
  });
};

export const handleInfoSuccess = (
  messageText,
  title = "Success",
  icon = "success"
) => {
  return Swal.fire({
    title: title,
    text: messageText,
    icon: icon,
    customClass: {
      icon: "swal2-icon-default",
    },
  });
};

export const handleInfo = (messageText, title = "Empty", icon = "info") => {
  return Swal.fire({
    text: messageText,
  });
};

export const handleDelete = async (messageText, action) => {
  await Swal.fire({
    icon: "warning",
    title: "Warning",
    text: messageText,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    denyButtonText: `Cancel`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      await action();
      return Promise.resolve("success");
    }
    return Promise.reject("cancel");
  });
};

export const handleInput = async (
  messageText,
  icon = "warning",
  title = "Need some more data",
  type = "text"
) => {
  return await Swal.fire({
    icon: icon,
    title: title,
    text: messageText,
    input: type,
    showLoaderOnConfirm: true,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    denyButtonText: `Cancel`,
  }).then((result) => {
    if (result.isConfirmed) {
      return Promise.resolve(result?.value);
    }
    return Promise.reject("cancel");
  });
};

export const safeQueryBuilder = (query, params) => {
  if (!!!params) return query;
  for (var key in params) {
    if (!query.includes("?") && params[key]) {
      query = query + "?" + key + "=" + params[key];
    } else if (params[key]) {
      query = query + "&" + key + "=" + params[key];
    }
  }
  return query;
};

export const searchQueryBuilder = (query, params) => {
  if (!!!params) return query;
  params.map((data, index) => {
    if (!query.includes("search") && data.value && data.operation) {
      var search = "&search=";
      if (!query.includes("?")) {
        search = "?search=";
      }
      query =
        query + search + data.key + data.operation + formatFilter(data.value);
    } else if (data.value && data.operation) {
      query =
        query + "," + data.key + data.operation + formatFilter(data.value);
    }
  });

  return query;
};

export const searchBuilder = (data) =>
  data?.key + data?.operation + data?.value;

export const downloadAsCsv = (content, fileName, mimeType) => {
  var a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
};

export const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      printColorAdjust: "exact",
    }}
  />
);

export const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      // console.dir(error);
      return getErrorsFromValidationError(error);
    }
  };
};

export const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner?.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const cleanFormData = (form) => {
  Object.keys(form).forEach(
    (key) =>
      (form[key] === undefined ||
        form[key] === null ||
        form[key] === "" ||
        form[key] === "Invalid date") &&
      delete form[key]
  );
  return form;
};

/** below is utils used in transaction creation and update*/

export const updateTransactionTotalValue = (
  dataM,
  { setFieldValue, values, delivery }
) => {
  var delivery = Number(delivery || (values?.delivery ?? 0));
  if (dataM == undefined || (dataM && dataM.length < 1)) {
    setFieldValue("value", delivery);
    setFieldValue("originValue", delivery);
    return;
  }

  var total = dataM
    ?.map((row) => row.subTotal)
    ?.reduce((subTotal, curr) => Number(curr) + Number(subTotal));
  setFieldValue("value", twoDecimal(Number(total) + delivery));
  setFieldValue("originValue", twoDecimal(Number(total) + delivery));
  setFieldValue(
    "change",
    twoDecimal(Number(values?.cash ?? 0) - (Number(total) + delivery))
  );
};

export const handleDeleteRow = async (
  index,
  { setFieldValue, values, invFeId }
) => {
  let newData = [...values?.dataM];
  // console.log(invFeId);
  if (invFeId) {
    index = newData?.findIndex((e) => e.invFeId == invFeId);
  }

  let dataToRemove = newData[index];
  // console.log(`index= ${index}`);
  // console.log(newData);
  if (index > -1) {
    // only splice array when item is found
    newData.splice(index, 1); // 2nd parameter means remove one item only
  }
  // console.log(newData);
  if (!!!dataToRemove?.invGroupId) {
    newData = newData.filter((item) => {
      // console.log(
      //   `${item?.invGroupId} | ${dataToRemove.invFeId} = ${
      //     item?.invGroupId != dataToRemove.invFeId
      //   }`
      // );
      return !!!item?.invGroupId || item?.invGroupId != dataToRemove.invFeId;
    });
  }

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

export const newLine = ({ type, editableName }) => {
  return {
    invFeId: uuid(),
    sku: "-",
    name: type ?? "",
    description: "-",
    discount: 0,
    discountType: null,
    priceBuyPerUnit: 0,
    priceSellPerUnit: 0,
    stockToCount: 1,
    stockToCountProduct: 1,
    subTotal: 0,
    editableName: editableName,
  };
};

export const addNewLine = ({
  setFieldValue,
  values,
  index,
  type,
  editableName = true,
}) => {
  setFieldValue("dataM", [...values?.dataM, newLine({ type, editableName })]);
  return true;
};

export const handleTrxTypeChange = ({ values, setFieldValue }) => {
  let newData = values?.dataM?.map((product) => {
    const priceUnit = values?.trxType
      ? product?.priceBuyPerUnit
      : product?.priceSellPerUnit;
    product.subTotal = priceUnit * product.stockToCount;
    return product;
  });

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

export const sorter = (e, { setSortBy, setIsAsc }) => {
  if (setSortBy != null) {
    if (e.column === "billStatus") {
      setSortBy("status");
    } else if (e.column === "isActive") {
      setSortBy("active");
    } else if (e.column) {
      setSortBy(e.column);
    }
  }

  if (setIsAsc == null) return;
  setIsAsc(e.asc);
};

export const sorterConfig = { external: true, resetable: false };

/** below use for product extraction */

export const extractProductOptions = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapProduct(eachData);
  });

export const mapProduct = (eachData) => {
  return {
    value: eachData?.invProductId,
    label: (
      <>
        <label className="pb-0 mb-0">{eachData?.name}</label>
        <br />
        <small className="text-muted pb-0 mb-0">
          {eachData?.brand ? eachData?.brand : ""}
        </small>
        <small className="text-muted pb-0 mb-0">
          {eachData?.model?.label
            ? eachData?.brand
              ? " " + eachData?.model?.label
              : eachData?.model?.label
            : ""}
        </small>
        <small className="text-muted pb-0 mb-0">
          {eachData?.category ? " " + eachData?.category : ""}
        </small>
        <p className="text-muted pb-0 mb-0">
          <small>{eachData?.sku ?? ""}</small>
        </p>
      </>
    ),
    InvProductLogId: eachData?.invProductLogId,
    paid: eachData?.paid,
    sku: eachData?.sku,
    name: eachData?.name,
    moq: eachData?.moq,
    description: eachData?.description,
    priceBuyPerUnit: eachData?.priceBuyPerUnit,
    stockToCount: eachData?.stockToCount,
    priceSellPerUnit: eachData?.priceSellPerUnit,
    partnerId: eachData?.partnerId,
    printerId: eachData?.printerId,
    printerName: eachData?.printerName,
    unPaidQuantity: eachData?.unPaidQuantity,
  };
};

export const handleStockChange = (
  index,
  key,
  data,
  { setFieldValue, values, invFeId, label }
) => {
  let newData = [...values?.dataM];

  if (invFeId) {
    index = newData?.findIndex((e) => e.invFeId == invFeId);
  }

  if (["stockToCountProduct"].includes(key)) {
    newData[index][key] = Number(data ?? 0);
  } else {
    newData[index][key] = data;
  }
  if (
    [
      "priceSellPerUnit",
      "priceBuyPerUnit",
      "stockToCount",
      "discount",
      "discountType",
    ].includes(key)
  ) {
    const priceUnit = values?.trxType
      ? newData[index]["priceSellPerUnit"]
      : newData[index]["priceBuyPerUnit"];
    var subTotal = twoDecimal(priceUnit * newData[index]["stockToCount"]);

    if (
      newData[index]["discountType"] == "percent" &&
      newData[index]["discount"]
    ) {
      subTotal = (subTotal * (100 - newData[index]["discount"] ?? 0)) / 100;
    } else if (
      newData[index]["discountType"] == "flat" &&
      newData[index]["discount"]
    ) {
      subTotal = subTotal - newData[index]["discount"] ?? 0;
    }

    newData[index]["subTotal"] = subTotal;
  }

  if (["salePersonId"].includes(key)) {
    newData[index]["salePersonName"] = label;
  }

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

/**Partner helper */

export const extractPartnerOptions = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return {
      value: eachData?.partnerId,
      label: (
        <label className="pb-0 mb-0">
          {eachData?.name?.trim()
            ? eachData?.name?.trim()
            : eachData?.companyName?.trim()}
          <small>
            {eachData?.telNo?.trim() ? " " + eachData?.telNo?.trim() : ""}
          </small>
          <small>
            {" "}
            {eachData?.email?.trim() ? " " + eachData?.email?.trim() : ""}
          </small>
          <br />
          <small className="text-muted pb-0 mb-0">
            {eachData?.address?.trim()}
          </small>
          {eachData?.name?.trim() ? (
            <p className="pb-0 mb-0">
              <small className="text-muted">
                {eachData?.companyName?.trim()}
              </small>
            </p>
          ) : (
            ""
          )}
          {eachData?.type == "CUSTOMER" ? (
            <p className="pb-0 mb-0">
              <small className="text-muted">
                total sales: RM{formatDouble(eachData?.spend ?? 0)}
              </small>
              {(eachData?.cash ?? 0) > (eachData?.spend ?? 0) ? (
                <>
                  <br />
                  <small className="text-muted">
                    extra payment: RM
                    {formatDouble(
                      (eachData?.cash ?? 0) - (eachData?.spend ?? 0)
                    )}
                  </small>
                </>
              ) : (
                <>
                  <br />
                  <small className="text-muted">
                    total pending payment: RM
                    {formatDouble(
                      (eachData?.cash ?? 0) - (eachData?.spend ?? 0)
                    )}
                  </small>
                </>
              )}
            </p>
          ) : eachData?.type == "SUPPLIER" ? (
            <p className="pb-0 mb-0">
              <small className="text-muted">
                total purchase: RM{formatDouble(eachData?.spend ?? 0)}
              </small>
              <br />
              <small className="text-muted">
                total expenses: RM{formatDouble(eachData?.spendExpenses ?? 0)}
              </small>
              {(eachData?.cash ?? 0) > (eachData?.spend ?? 0) ? (
                <>
                  <br />
                  <small className="text-muted">
                    extra payment: RM
                    {formatDouble(
                      (eachData?.cash ?? 0) - (eachData?.spend ?? 0)
                    )}
                  </small>
                </>
              ) : (
                <>
                  <br />
                  <small className="text-muted">
                    total pending payment: RM
                    {formatDouble(
                      (eachData?.cash ?? 0) - (eachData?.spend ?? 0)
                    )}
                  </small>
                </>
              )}
            </p>
          ) : (
            ""
          )}
        </label>
      ),
    };
  });

export const extractStaffOptions = (responseToParse, configType) =>
  responseToParse?.map((eachData) => {
    return {
      value: eachData?.partnerId,
      label: eachData?.name,
    };
  });

export const extractGroupOptions = (data) =>
  data
    ? data.map((group) => {
        return {
          value: group.partnerGroupId,
          label: group.name,
          partnerGroupId: group.partnerGroupId,
        };
      })
    : data;

export const setInitialProductList = (products, trxType) => {
  if (!products) return [];
  return products.map((product) => {
    var subTotal = trxType
      ? twoDecimal(product?.stockToCount * product?.priceSellPerUnit)
      : twoDecimal(product?.stockToCount * product?.priceBuyPerUnit);

    if (product.discountType == "percent") {
      subTotal =
        (Number(subTotal) * (100 - Number(product.discount ?? 0))) / 100;
    } else {
      subTotal = Number(subTotal) - Number(product.discount ?? 0);
    }

    return {
      invProductLogId: product?.invProductLogId,
      paid: product?.paid,
      invProductId: product?.invProductId,
      sku: product?.sku,
      name: product?.name,
      description: product?.description,
      discount: product?.discount ?? 0,
      discountType: product?.discountType,
      editableName: product?.editableName,
      stockToCount: product?.stockToCount,
      stockToCountProduct: product?.stockToCountProduct,
      priceSellPerUnit: product?.priceSellPerUnit,
      priceBuyPerUnit: product?.priceBuyPerUnit,
      invGroupId: product?.invGroupId,
      invFeId: product?.invFeId ?? uuid(),
      salePersonName: product?.salePersonName,
      salePersonId: product?.salePersonId,
      subTotal: subTotal,
      printerId: product?.printerId,
      printerName: product?.printerName,
      unPaidQuantity: product?.unPaidQuantity,
    };
  });
};
export const FeedBackInput = ({ invalid, error }) => {
  return invalid && error ? (
    <label className="form-label" style={{ color: "red" }}>
      {error}
    </label>
  ) : (
    <></>
  );
};

export const extractPrinterOption = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapPrinter(eachData);
  });

export const mapPrinter = (printer) => {
  return {
    value: printer?.settingId,
    label: printer?.name,
  };
};

export const extractModelOption = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapModel(eachData);
  });

export const mapModel = (model) => {
  return {
    value: model?.keyValueId,
    label: model?.label,
  };
};

export const roundingAdjustment = (total, cashIn) => {
  var balance = Number(cashIn ?? 0) - Number(total ?? 0);

  var result = twoDecimal(balance);
  // if (result < 0) {
  //   return 0;
  // }
  return result;
};

export const handleBalance = (cashIn, values, setFieldValue) => {
  cashIn = Number(cashIn ?? 0);

  if (cashIn && cashIn > 0 && values?.value && Number(values?.value ?? 0) > 0) {
    setFieldValue("change", roundingAdjustment(values.value, cashIn));
    setFieldValue("partialBalance", roundingAdjustment(cashIn, values.value));
    if (Number(cashIn ?? 0) < Number(values.value ?? 0)) {
      setFieldValue("billStatus", "PARTIAL");
    } else {
      setFieldValue("billStatus", "PAID");
    }
  }
};

export const twoDecimal = (val) => {
  if (!val) return 0.0;

  return Number(val ?? 0.0).toFixed(2);
};

export const tabActive = (history, defaultTab) => {
  return history?.location?.hash?.replace("#", "") == ""
    ? defaultTab
    : history?.location?.hash?.replace("#", "");
};

export const extractPartnerCodeOptions = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return {
      value: eachData?.partnerId,
      label:
        eachData?.name +
        (eachData?.telNo ? " | " + eachData?.telNo : "") +
        (eachData?.email ? " | " + eachData?.email : ""),
      raw: eachData,
    };
  });

export const InvoiceTitle = (configType = "", invoiceType = "") => {
  const { t } = useTranslation();
  if (
    configType.includes(
      "OTHERSarrREFUNDarrCASHOUTarrCAPITALSpAcEEQUITYarrCAPITALSpAcEDEPTHarrPURCHASEarrEXPENSES"
    )
  )
    return t("Purchase & Expenses");
  if (invoiceType.includes("ORDER") && configType.includes("SALE"))
    return t("Sale Order");
  if (invoiceType.includes("ORDER") && configType.includes("PURCHASE"))
    return t("Purchase Order");

  if (invoiceType.includes("QUOTATION")) return t("Quotation");

  if (configType == "SALESarrINCOMESpAcEOTHER") return t("Sale / Other Income");
  if (configType == "SALES" || configType == "SALE") return t("Sale");

  if (
    configType.includes("INCOMESpAcEOTHER") ||
    (configType.includes("CAPITAL DEPTH".replaceAll(" ", "SpAcE")) &&
      !configType.includes("CAPITAL DEPTH CREDIT".replaceAll(" ", "SpAcE")))
  )
    return t("Other Income");

  if (configType.includes("PURCHASE")) return t("Purchase");
  if (configType == "EXPENSES") return t("Expenses");
  if (configType == "OTHERS") return t("Non-deductable expenses");
  if (configType == "CAPITAL EQUITY".replaceAll(" ", "SpAcE"))
    return t("Capital");
  if (configType == "CAPITAL DEPTH".replaceAll(" ", "SpAcE")) return t("Loan");
  if (configType == "CAPITAL DEPTH CREDIT".replaceAll(" ", "SpAcE"))
    return t("Loan - credit");
  if (configType == "CASHOUT") return t("Dividen Payment");
  if (configType == "REFUND") return t("Refund");
  if (configType == "ASSET FIXED".replaceAll(" ", "SpAcE"))
    return t("Fixed asset");
  if (configType == "ASSET CURRENT".replaceAll(" ", "SpAcE"))
    return t("Current asset");
  if (configType == "TRANSFER DEBIT".replaceAll(" ", "SpAcE"))
    return t("Transfer debit");
  if (configType == "TRANSFER CREDIT".replaceAll(" ", "SpAcE"))
    return t("Transfer credit");

  return "Refund / Non-deductable expenses / Other expenses";
};

export const CustomerType = (configType = "", invoiceType = "") => {
  if (invoiceType.includes("ORDER") && configType.includes("SALE"))
    return "CUSTOMER";
  if (invoiceType.includes("ORDER") && configType.includes("PURCHASE"))
    return "SUPPLIER";

  if (invoiceType.includes("QUOTATION")) return "CUSTOMER";

  if (configType.includes("SALE") || configType.includes("INCOME OTHER"))
    return "CUSTOMER";
  if (configType.includes("PURCHASE")) return "SUPPLIER";

  if (
    configType.includes(
      "INCOMESpAcEOTHERarrCAPITALSpAcEDEPTHarrCAPITALSpAcEEQUITYarrCAPITALSpAcEEQUITYarrCAPITALSpAcEDEPTHSpAcECREDITSpAcEREPAYMENT"
    )
  )
    return "CUSTOMER";

  return "SUPPLIER";
};

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export const copyToClipboard = (window, text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const setHtmlTitle = (title) => {
  const el = document.querySelector("title");
  el.innerText = title;
};

export const setHtmlDescription = (type = "description", desc) => {
  const el = document.querySelector(`meta[name="${type}"]`);
  el.setAttribute("content", desc);
};
