import { Provider } from "@rollbar/react";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { Loader, isMobileApp } from "./utils/tableUtils";
import InvoicePublic from "./views/apps/invoicing/InvoicePublicV2";
import ErrorBoundary from "./views/pages/errorBoundary/ErrorBoundary";
import StripeHardwarePuschaseComplete from "./views/pages/register/StripeHarwarePurchaseComplete";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const NewSubscribtion = React.lazy(() =>
  import("./views/pages/register/NewSubscribtion")
);
const NewSubscriptionComplete = React.lazy(() =>
  import("./views/pages/register/NewSubscriptionComplete")
);
const StripeCheckoutComplete = React.lazy(() =>
  import("./views/pages/register/StripeCheckoutComplete")
);
const RegisterInvitedAcc = React.lazy(() =>
  import("./views/pages/register/RegisterInvitedAcc")
);
const Confirm = React.lazy(() => import("./views/pages/register/Confirm"));
const Checkout = React.lazy(() => import("./views/pages/register/Checkout"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ResetPassword")
);

var _rollbarConfig = {
  accessToken: "bdec69126f404861a6f330b89cd79b59",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  environment: "production",
};

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Provider config={_rollbarConfig}>
          <ErrorBoundary>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/subscribe/new"
                name="subscribe new Page"
                render={(props) => <NewSubscribtion {...props} />}
              />
              <Route
                exact
                path="/subscribe/complete"
                name="subscribe new complate Page"
                render={(props) => <NewSubscriptionComplete {...props} />}
              />
              <Route
                exact
                path="/stripe/complete"
                name="subscribe new complate Page"
                render={(props) => <StripeCheckoutComplete {...props} />}
              />
              <Route
                exact
                path="/stripe/complete/purchase"
                name="pruchase complate Page"
                render={(props) => (
                  <StripeHardwarePuschaseComplete {...props} />
                )}
              />
              <Route
                exact
                path="/subscribe/renewal/complete"
                name="subscribe renewal complete Page"
                render={(props) => <NewSubscriptionComplete {...props} />}
              />
              <Route
                exact
                path="/register/user"
                name="Register Page"
                render={(props) => <RegisterInvitedAcc {...props} />}
              />
              <Route
                exact
                path="/confirm"
                name="Confirm Page"
                render={(props) => <Confirm {...props} />}
              />
              <Route
                exact
                path="/forgotPassword"
                name="Forgot Password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/resetPassword"
                name="Forgot Password"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path="/checkout"
                name="Checkout"
                render={(props) => <Checkout {...props} />}
              />
              <Route
                exact
                path="/pb/:id"
                render={(props) => <InvoicePublic {...props} />}
              />
              <PrivateRoute
                authed={isAuthenticated}
                path="/"
                component={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </ErrorBoundary>
        </Provider>
      </React.Suspense>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </BrowserRouter>
  );
};

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          (window.location.pathname === "" ||
            window.location.pathname === "/" ||
            window.location.pathname === "/board") &&
          process.env.NODE_ENV !== "development" ? (
            (window.location.href = process.env.REACT_APP_BASE + "/board")
          ) : (
            <Component {...props} />
          )
        ) : process.env.NODE_ENV === "development" ? (
          window.location.pathname !== "/login" &&
          (window.location.href = process.env.REACT_APP_BASE + "/login")
        ) : window.location.pathname === "/login" ? (
          window.location.pathname === "/login"
        ) : isMobileApp(window) ? (
          window.location.pathname === "/login"
        ) : (
          (window.location.href = "https://main.eas-pro.my")
        )
      }
    />
  );
}

export default App;
