import axios from "axios";
import { handleInfo, handleInfoToast } from "src/utils/formUtils";
import Swal from "sweetalert2";
import { setCookie } from "./authentication/aut";

// axios.defaults.timeout = 10000;
axios.defaults.headers["accept"] = "application/json";
axios.defaults.headers["content-type"] = "application/json";
axios.defaults.withCredentials = true;

export const getBaseUrl = (app) => {
  if (app == "eas") {
    return process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SERVICE_HOST
      : process.env.REACT_APP_BASE + "/eas/api";
  }

  if (app == "subscription") {
    return process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SUBSCRIBE_SERVICE_HOST
      : process.env.REACT_APP_BASE + "/subscription/api";
  }

  if (app == "inventory") {
    return process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_INVENTORY_SERVICE_HOST
      : process.env.REACT_APP_BASE + "/inventory/api";
  }
};

export const mainAxios = axios.create({
  baseURL: getBaseUrl("eas"),
});

const subscribeAxios = axios.create({
  baseURL: getBaseUrl("subscription"),
});

const inventoryAxios = axios.create({
  baseURL: getBaseUrl("inventory"),
});

mainAxios.interceptors.request.use(
  axiosRequstChecks(),
  axiosRequestErrorHandler()
);

mainAxios.interceptors.response.use(
  axiosResponseHandler(),
  axiosResponseErrorHandler()
);

subscribeAxios.interceptors.request.use(
  axiosRequstChecks(),
  axiosRequestErrorHandler()
);

subscribeAxios.interceptors.response.use(
  axiosResponseHandler(),
  axiosResponseErrorHandler()
);

inventoryAxios.interceptors.request.use(
  axiosRequstChecks(),
  axiosRequestErrorHandler()
);

inventoryAxios.interceptors.response.use(
  axiosResponseHandler(),
  axiosResponseErrorHandler()
);

// to help debug loading SWAL. must use asyn await
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export async function post({ path, formData }) {
  return mainAxios.post(path, formData);
}

export async function patch({ path, formData }) {
  return mainAxios.patch(path, formData);
}

export async function get({ path, formData }) {
  return mainAxios.get(path, formData);
}

export async function deleteEle({ path }) {
  return mainAxios.delete(path);
}

export function postSubscribe({ path, formData }) {
  return subscribeAxios.post(path, formData);
}

export function patchSubscribe({ path, formData }) {
  return subscribeAxios.patch(path, formData);
}

export function getSubscribe({ path }) {
  return subscribeAxios.get(path);
}

export function deleteEleSubscribe({ path }) {
  return subscribeAxios.delete(path);
}

export function postInventory({ path, formData }) {
  return inventoryAxios.post(path, formData);
}

export function patchInventory({ path, formData }) {
  return inventoryAxios.patch(path, formData);
}

export function getInventory({ path }) {
  return inventoryAxios.get(path);
}

export function deleteEleInventory({ path }) {
  return inventoryAxios.delete(path);
}

/*
  below is axios common inteceptor handler that being share multiple axios constant
*/

function axiosResponseErrorHandler() {
  Swal.close();

  return function (error) {
    const status = error?.response?.status;
    var errorBody = error?.response?.data?.response;
    var message = errorBody?.status?.httpMessage;

    if (
      (error.message === "Network Error" || status == 502) &&
      window.location.pathname !== "/503"
    ) {
      window.location = "/503";
    } else if (status == 401 && window.location.pathname !== "/login") {
      setCookie("active", false);
      window.location = "/login";
    } else if (status == 400 && window.location.pathname !== "/project") {
      window.location = "/project";
    } else {
      var code = errorBody?.status?.code;

      if (
        message?.includes("subscription expired") &&
        window.location.pathname !== "/subscribe"
      ) {
        window.location = "/subscribe";
      }

      if (message?.includes("Failed to authenticate")) {
        handleInfo(message, "Failed", "warning");
      }

      if (message?.includes("please consider upgrade your plan")) {
        handleInfo(message, "Failed", "warning");
      }

      if (status == 406 || code == "406") {
        handleInfoToast(message);
      }

      if (status == 408 || code == "408") {
        handleInfoToast(message, "failed");
      }
    }
    return Promise.reject(error);
  };
}

function axiosResponseHandler() {
  return function (response) {
    const data = response.data.data;
    response.data = data;
    Swal.hideLoading();
    return response;
  };
}

function axiosRequestErrorHandler() {
  Swal.close();
  return function (error) {
    // TODO send this to server
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
    return Promise.reject(error);
  };
}

function axiosRequstChecks() {
  return function (config) {
    const data = config.data;
    const form = {
      data,
    };
    config.data = form;
    return config;
  };
}
